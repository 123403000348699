<template>
  <div class="stage-message">
    <div v-if="!data.noMsgLikes" class="pb-2">
      {{ likePeopleText }}
    </div>
    <ul class="list-unstyled msg-list">
      <li v-for="(message, index) in data.defaultMsg" :key="index" class="pb-2 d-flex">
        <b>{{ message.name }}</b>
        <div v-html="message.content"></div>
      </li>
    </ul>
    <ul class="list-unstyled msg-list">
      <li v-for="(message, index) in messages" :key="index" class="pb-2">
        <div v-bind:class="[message.result]" class="d-flex">
          <b>{{ message.character || defaultWhoTalk }}</b>
          <div v-if="message.is_hint" @click="copy(message.content)" v-html="message.content"></div>
          <div v-else @click="copy(message.content)" v-html="message.content"></div>
        </div>
      </li>
    </ul>
    <div class="d-flex align-items-center mb-4" v-if="!clear">
      <div class="cha-circle circle mr-3 col-auto p-0">
        <img class="cover-fit" v-bind:src="showAvatar(character.picture)" />
      </div>
      <div class="">
        <input
          class="stage-input"
          type="text"
          :class="`stage-input-${step}`"
          v-model="talkContent"
          placeholder="輸入答案.."
        />
      </div>
      <a :class="`stage-submit-${step}`" role="button" class=" px-2 col-auto cursor-default" @click="doSendMessage"
        >送出</a
      >

      <a
        :class="`stage-hint-${step}`"
        role="button"
        class="stage-hint ml-1 text-decoration-none"
        @click="hintMessage"
        v-if="!flag(data.unlockFlag)"
      >
        <svg class="btn-icon-28">
          <use xlink:href="#icon-tip" class="fill-blue active"></use>
        </svg>
        <span style="">x{{ leftTipsTimes }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import { mapActions, mapGetters } from "vuex";
import { sendMessage } from "../../utils/WebSocket";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import ModalMixin from "../../Mixins/ModalMixin";
import FavorFromMixin from "../../Mixins/FavorFromMixin";
import StageFromMixin from "../../Mixins/StageFromMixin";
import likeCount from "../../Mixins/getLikeCount";
import StageMessageMixin from "../../Mixins/StageMessageMixin";

function stripTags(str) {
  var d = document.createElement("div");
  d.innerHTML = str;
  return d.textContent === undefined ? d.innerText : d.textContent;
}
export default {
  name: "stageMessage",
  mixins: [
    Avatar,
    CharacterFromVuex,
    TeamFromVuex,
    WebSocketMixin,
    ModalMixin,
    FavorFromMixin,
    StageFromMixin,
    likeCount,
    StageMessageMixin
  ],
  computed: {
    likePeopleText() {
      return this.likePeople();
    },
    leftTipsTimes() {
      return this.data.hint.filter(d => typeof d !== "object").length - this.usedHintCount;
    }
  },
  data() {
    return {
      limit: 10,
      show_all: true,
      talkContent: "",
      clear: false,
      like: 20,
      ishintBlock: false,
      usedHintCount: 0
    };
  },
  methods: {
    ...mapActions("Score", ["hintMapAdd"]),
    copy(content) {
      this.talkContent = stripTags(content);
    },
    likePeople: function() {
      let newLike = this.getRandomLike(this.like);
      this.like = newLike;
      return `${this.getRandomPeople()}和其他${newLike}個人都說讚`;
    },
    doSendMessage: function() {
      //stageCharacter=發言人  //character.name=user的名字

      let result = "";
      if (this.talkContent.length == 0) return;
      if (Array.isArray(this.data.answer)) {
        if (this.data.answer.includes(this.talkContent)) {
          this.setWinner({
            winner: this.character,
            user: this.user,
            stage: this.stage
          });
          result = "a-correct";
        } else {
          result = "a-wrong";
        }
      } else {
        if (this.talkContent == this.data.answer) {
          this.setWinner({
            winner: this.character,
            user: this.user,
            stage: this.stage,
            step: this.step
          });
          result = "a-correct";
        } else {
          result = "a-wrong";
        }
      }
      let newMsg = {
        character: this.character.name,
        content: this.talkContent,
        result: result
      };
      this.sendStageMessage({
        step: this.step,
        stage: this.stage,
        newMsg
      });
      if (result === "a-correct" && this.data.echoOfanswer) {
        if (Array.isArray(this.data.echoOfanswer)) {
          this.data.echoOfanswer.forEach((echoContent, index) => {
            let newMsg = {
              character: this.data.echoOfCharacter[index],
              content: echoContent,
              result: null,
              is_hint: true
            };
            this.sendStageMessage({
              step: this.step,
              stage: this.stage,
              newMsg
            });
          });
        } else {
          let newMsg = {
            character: this.stageCharacter,
            content: this.data.echoOfanswer,
            result: null,
            is_hint: true
          };
          this.sendStageMessage({
            step: this.step,
            stage: this.stage,
            newMsg
          });
        }
      }

      if (Array.isArray(this.data.answer)) {
        if (this.data.answer.includes(this.talkContent)) {
          this.$emit("stageClear", true);
        }
      } else {
        if (this.talkContent == this.data.answer) {
          this.$emit("stageClear", true);
        }
        this.talkContent = "";
      }
      this.talkContent = "";
    },
    hintMessage: function() {
      // console.log("hintCount", this.hint_key, this.hintCount(this.hint_key));
      if (this.ishintBlock) return;
      this.ishintBlock = true;
      setTimeout(() => {
        this.ishintBlock = false;
      }, 1000);
      let hintIndex = this.hint_key ? this.hintCount(this.hint_key) : this.data.hintIndex;

      if (typeof this.data.hint[hintIndex] === "object") {
        this.showModal(this.data.hint[hintIndex].content);
        this.hintMapAdd(this.hint_key);
        this.$emit("hintAdd");
        return;
      }
      if (hintIndex < this.data.hint.length) {
        if (this.hint_key) {
          this.hintMapAdd(this.hint_key);
        }
        this.usedHintCount++;
        let newMsg = {
          character: null,
          content: this.data.hint[hintIndex],
          result: "",
          is_hint: true
        };

        // console.log(this.data.hint[hintIndex])
        let messageData = {
          method: "StageMessage",
          token: this.token,
          stage: this.stage,
          step: this.step,
          only: this.user.id,
          hintIndex: hintIndex,
          newMsg
        };
        sendMessage(messageData);

        let score = 0;
        messageData = {
          method: "GetHint",
          token: this.token,
          stage: this.stage,
          step: this.step,
          only: this.user.id,
          score,
          hintIndex: hintIndex
        };
        sendMessage(messageData);

        this.$emit("hintAdd");
      }
    }
  }
};
</script>

<style scoped>
.stage-hint:active {
  transform: scale(0.8);
}
</style>
