<template>
  <div class="position-relative full-page fighting-bg">
    <logoutBtn position="upper-left"></logoutBtn>
    <teamWating></teamWating>
    <!-- <fullpageInfo></fullpageInfo> -->
    <div
      class="hint-circle d-flex justify-content-center align-items-center position-absolute"
      style="width: 7vw; right: 5vw;top: 5vw;"
      @click="showHint"
    >
      <img src="@/assets/images/icon-help.svg" alt="" />
    </div>
    <div v-if="isShowHint" style="padding: 25px 10px;width:calc(100vw - 50px);" class="app-modal">
      <p>如果有隊友沒有順利一起進入此畫面，請點擊「同步」將隊友同步至此畫面</p>
      <div
        style="display:inline-block; width:45%; margin-right:10%;"
        class="btn btn-outline-primary btn-block"
        @click="closeHint"
      >
        返回
      </div>
      <div
        style="display:inline-block; width:45%; margin-top:0;"
        class="btn btn-outline-primary btn-block"
        @click="doAction"
      >
        同步
      </div>
    </div>

    <gamePrepare :title="title" :hint="waitingText" :btnLink="btnLink"></gamePrepare>
    <div class="control-block position-absolute" v-show="isLeader">
      <button @click="fighting" class="btn btn-primary btn-block">開始</button>
    </div>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import gamePrepare from "@/views/part/gamePrepare";
import logoutBtn from "@/views/part/logoutBtn";

import UserFromVuex from "../Mixins/UserFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import { mapActions, mapGetters } from "vuex";
import teamWating from "@/views/part/teamWating";
import ModalMixin from "@/Mixins/ModalMixin";
import { sendMessage } from "../utils/WebSocket";

export default {
  name: "fightingStandBy",
  mixins: [UserFromVuex, WebSocketMixin, TeamFromVuex, ModalMixin],
  components: {
    fullpageInfo,
    gamePrepare,
    teamWating,
    logoutBtn
  },
  watch: {
    team_payload: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val?.fighting?.role?.player1) {
          this.btnLink[0].users = [val.fighting.role.player1];
        } else {
          this.btnLink[0].users = [];
        }
        if (val?.fighting?.role?.player2) {
          this.btnLink[1].users = [val.fighting.role.player2];
        } else {
          this.btnLink[1].users = [];
        }
        if (val?.fighting?.role?.supply) {
          this.btnLink[2].users = val.fighting.role.supply
            .map(d => d)
            .filter(d => {
              return !this.btnLink[0].users.includes(d) && !this.btnLink[1].users.includes(d);
            });
        }
        let all = this.btnLink.reduce((accumulator, currentValue) => accumulator + currentValue.users.length, 0);
        this.all_ready = all == this.members.length;
      }
    }
  },
  computed: {
    ...mapGetters("Team", ["isLeader"]),
    hasTwoPlayer() {
      return this.btnLink[1].users.length + this.btnLink[0].users.length == 2;
    }
  },
  data() {
    return {
      all_ready: false,
      title: "打架了！打架了！",
      waitingText: "等待隊員選擇中..",
      isShowHint: false,
      btnLink: [
        {
          name: "我上！打者1P",
          link: "",
          event: this.choseRole("player1"),
          users: []
        },
        {
          name: "我上！打者2P",
          link: "",
          event: this.choseRole("player2"),
          users: []
        },
        {
          name: "當鄉民幫忙加油",
          link: "",
          event: this.choseRole("supply"),
          users: []
        }
      ]
    };
  },
  mounted: function() {},
  methods: {
    ...mapActions("Stage", ["fightingChoseRole", "fightingByStand"]),

    choseRole(role) {
      return () => {
        this.fightingChoseRole({
          role,
          character: this.character
        });
      };
    },
    showHint: function() {
      this.isShowHint = true;
    },
    closeHint: function() {
      this.isShowHint = false;
    },
    doAction: function() {
      this.isShowHint = false;
      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "fightingStandBy"
        }
      };
      sendMessage(data);
    },
    fighting() {
      if (!this.all_ready) {
        this.showModal("要等大家都選好角色歐");
        return;
      }

      if (!this.hasTwoPlayer) {
        // alert("1p 2p都要有人選歐");
        this.showModal("1p 2p都要有人選");
        return;
      }

      if (this.all_ready && this.isLeader) {
        if (!this.allMemberOnline) {
          this.showModal("請確認所有人都在線上才能開始遊戲");
          return;
        }
        if (confirm("確定要開始遊戲了嗎?")) {
          this.fightingByStand();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.control-block {
  padding: 30px;
  bottom: 0;
  width: 100%;
}
</style>
