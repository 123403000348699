<template>
  <div @click="videoControl">
    <div v-show="flag('stage_4_phase1Clear')">
      <img class="img-fluid" src="/img/page-turn-answer.jpg" alt="" />
    </div>
    <div class="position-relative">
      <template v-if="!flag('stage_4_phase1Clear')">
        <img v-show="!start" class="img-fluid" src="/img/page-turn.jpg" alt="" />
        <template v-if="start && !openNote">
          <div v-if="correct" @click="openNote = true" class="note-area position-absolute"></div>
          <img v-show="!play && correct" class="img-fluid" src="/img/page-turn-right.jpg" alt="" />
          <img v-show="!play && !correct" class="img-fluid" src="/img/page-turn-wrong.jpg" alt="" />
        </template>
        <template v-if="openNote">
          <img class="img-fluid" src="/img/page-turn-note.jpg" alt="" />
        </template>
        <div v-show="play" class="video-wrap">
          <video ref="video" :src="getVideoPath('turn-page')" playsinline loop></video>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StageFromMixin from "../../Mixins/StageFromMixin";

export default {
  name: "turnPage",
  mixins: [StageFromMixin],
  computed: {
    ...mapGetters("Video", ["getVideoPath"])
  },
  data() {
    return {
      start: false,
      play: false,
      correct: false,
      openNote: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.video) {
        this.$refs.video.addEventListener("timeupdate", () => {
          // console.log(this.$refs.video.currentTime);
        });
        this.$refs.video.addEventListener("play", () => {
          this.start = true;
          this.play = true;
        });
        this.$refs.video.addEventListener("pause", () => {
          this.play = false;
          let currentTime = this.$refs.video.currentTime;
          if (currentTime >= 1.0 && currentTime <= 1.3) {
            this.correct = true;
          }
        });
      }
    });
  },
  methods: {
    videoControl: function() {
      if (!this.correct) {
        if (this.play) {
          this.$refs.video.pause();
        } else {
          this.$refs.video.play();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.video-wrap {
  height: 123.97vw;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.note-area {
  left: 18%;
  top: 58%;
  width: 40vw;
  height: 15vh;
  transform: rotate(35deg);
}
</style>
