<template>
  <div>
    <l-map ref="map" v-bind="map_config" style="height: calc(80vh - 40px)">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker :lat-lng="userCenter" :icon="user_location.icon"></l-marker>

      <component :is="marker.is" v-for="(marker, index) in markers" :key="index" v-bind="marker.options"></component>
    </l-map>
  </div>
</template>

<script>
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import { LMap, LTileLayer, LMarker, LPolyline } from "vue2-leaflet";
import {getCurrentPosition} from "../../libs/getCurrentPosition";

export default {
  name: "miniMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline
  },
  props: {
    map_config: {
      required: true
    },
    flyTo: {
      required: false,
      default: false
    },
    defaultUserCenter: {
      required: true
    },
    markers: {
      default: () => []
    }
  },
  data() {
    return {
      updateUserCenterTimeout: null,
      userCenter: [25.0436, 121.5139],
      user_location: {
        icon: icon({
          iconUrl: "/img/location.svg",
          iconSize: [100, 100],
          iconAnchor: [50, 50]
        })
      },
      url: "https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png"
    };
  },
  created() {
    this.updateUserCenter();
    this.updateUserCenterTimeout = setInterval(this.updateUserCenter, 20000);
  },
  mounted() {
    const mapComponent = this.$refs.map.mapObject;
    // this.userCenter = this.defaultUserCenter;
    if (this.flyTo) mapComponent.flyTo(this.flyTo, 16);
  },
  methods: {
    getIcon(data) {
      return icon(data);
    },
    updateUserCenter() {
      if (this.defaultUserCenter) return;
      if (navigator.geolocation) {
        getCurrentPosition(
          position => {
            let p = position.coords;
            // 將中心點設為目前的位置
            console.log(p);
            this.userCenter = [p.latitude, p.longitude];
            console.log(this.userCenter);
            // console.log(this.map_config.center)
          },
          err => {
            alert(err.message);
          }
        );
      } else {
        alert("您的瀏覽器不支援地理定位");
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.updateUserCenterTimeout);
  }
};
</script>

<style scoped></style>
