<template>
  <div class="position-relative" @click="cdClick">
    <findPlace v-if="findPlaceShow"></findPlace>
    <svg class="btn-icon-28 hint-btn" @click.stop="hintBtnClick">
      <use xlink:href="#icon-tip" class="fill-blue active"></use>
    </svg>
    <img class="img-fluid" src="/img/csh-find.jpg" alt="" />
    <img
      v-if="flag('stage_3_phase2_find1_clear')"
      src="/img/csh-find-1-p.png"
      class="position-absolute road-person"
      alt=""
      @click="showNextMsg"
    />
    <img v-if="flag('stage_3_phase2_find1_p1')" class="position-absolute ans-circle" src="/img/csh-find-1.png" alt="" />
    <img v-if="flag('stage_3_phase2_find1_p2')" class="position-absolute ans-circle" src="/img/csh-find-2.png" alt="" />
    <img v-if="flag('stage_3_phase2_find1_p3')" class="position-absolute ans-circle" src="/img/csh-find-3.png" alt="" />
    <svg
      class="position-absolute ans-circle click-detect"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 750 930"
      style="enable-background:new 0 0 750 930;"
      xml:space="preserve"
    >
      <g class="click-area" @click.stop.prevent="clickArea(0)">
        <ellipse class="" cx="374.2" cy="504.8" rx="182.2" ry="39.8" />
      </g>
      <g class="click-area" @click.stop.prevent="clickArea(1)">
        <path class="" d="M112,680c0,0,56,24,39,45S80,721,112,680z" />
        <path class="" d="M272,670c0,0,73,17,57,50S246,708,272,670z" />
        <path class="" d="M438,670c0,0,38,4,38,36s-33,30-38,27S416,701,438,670z" />
        <path class="" d="M596,666c0,0,46,6,49,36s-31,39-45,30S564,690,596,666z" />
      </g>
      <g class="click-area" @click.stop.prevent="clickArea(2)">
        <path class="" d="M367,749c29.9,2,223,15,224,31s-187,20-241,22s-219-10-222-26S276,743,367,749z" />
      </g>
    </svg>
  </div>
</template>

<script>
import StageFromMixin from "../../Mixins/StageFromMixin";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import UserFromVuex from "../../Mixins/UserFromVuex";
import findPlace from "@/views/part/findPlace";
import delayEmit from "@/Mixins/delayEmit";
import FindMixin from "@/Mixins/FindMixin";

export default {
  name: "zshFind1",
  components: {
    findPlace
  },
  mixins: [StageFromMixin, TeamFromVuex, UserFromVuex, delayEmit, FindMixin],
  props: {
    step: {
      default: 0
    }
  },
  data() {
    return {
      findPlaceShow: false,
      canClick: true,
      // correctCount: 0,
      detectArea0: false,
      detectArea1: false,
      detectArea2: false
    };
  },
  computed: {
    correctCount: function() {
      let count = 0;
      if (this.flag("stage_3_phase2_find1_p1")) {
        count++;
      }
      if (this.flag("stage_3_phase2_find1_p2")) {
        count++;
      }
      if (this.flag("stage_3_phase2_find1_p3")) {
        count++;
      }
      return count;
    }
  },
  methods: {
    cdClick() {
      if (!this.canClick) return;

      if (!this.flag("stage_3_phase2_find1_clear")) {
        this.findPlaceShow = true;
        this.canClick = false;
        setTimeout(() => {
          this.canClick = true;
          this.findPlaceShow = false;
        }, 5000);
      }
    },
    clickArea(area) {
      if (!this.canClick) return;
      switch (area) {
        case 0:
          if (!this.flag("stage_3_phase2_find1_p1")) {
            this.hideFindPlaceShow();
            this.setFlag({ key: "stage_3_phase2_find1_p1", status: true });
            this.delayEmit("showfavor");
            // this.addScore(2);
            // this.correctCount++;
          }
          break;
        case 1:
          if (!this.flag("stage_3_phase2_find1_p2")) {
            this.hideFindPlaceShow();
            this.setFlag({ key: "stage_3_phase2_find1_p2", status: true });
            this.delayEmit("showfavor");
            // this.addScore(2);

            // this.correctCount++;
          }
          break;
        case 2:
          if (!this.flag("stage_3_phase2_find1_p3")) {
            this.hideFindPlaceShow();
            this.setFlag({ key: "stage_3_phase2_find1_p3", status: true });
            this.delayEmit("showfavor");
            // this.addScore(2);

            // this.correctCount++;
          }
          break;
      }
      if (this.correctCount >= 3) {
        this.hideFindPlaceShow();
        this.setFlag({ key: "stage_3_phase2_find1_clear", status: true });
      }
    },
    showNextMsg() {
      this.setFlag({ key: "stage_3_phase2_find2", status: true });
    },
    hintBtnClick() {
      this.$emit("hintBtnClick", "phase2Find1", this.step);
    }
  }
};
</script>

<style scoped lang="scss">
.hint-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
}
.road-person {
  right: 12vw;
  bottom: 2vw;
  height: 30vw;
  z-index: 99;
}

.click-area {
  fill: rgba(255, 255, 255, 0);
}

.ans-circle {
  width: 100%;
  left: 0;
  top: 0;
}

.click-detect {
  z-index: 10;
}
</style>
