import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Result", ["stagePhoto", "photos"])
  },
  methods: {
    ...mapActions("Result", ["syncPhoto"])
  }
};
