<template>
  <div>
    <favorMomo
      v-if="now_step == 0 || stageFinished"
      :winner="winner"
      @next="next"
      :stageFinished="stageFinished"
    ></favorMomo>
    <favorShi v-if="now_step == 1" :stage="stage" :step="step" :score="score" @next="next"></favorShi>
    <favorBailan v-if="now_step == 2" @next="next"></favorBailan>
  </div>
</template>

<script>
import CharacterFromVuex from "../../Mixins/CharacterFromVuex";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import favorBailan from "../part/favorBailan";
import favorMomo from "../part/favorMomo";
import favorShi from "../part/favorShi";
import FavorFromMixin from "../../Mixins/FavorFromMixin";
import { mapActions } from "vuex";

export default {
  name: "FavorContainer",
  mixins: [CharacterFromVuex, TeamFromVuex, FavorFromMixin],
  components: {
    favorShi,
    favorMomo,
    favorBailan
  },
  props: {
    stage: null,
    step: null,
    maximum_step: {
      default: 3,
      type: Number
    },
    score: {
      default: 10
    },
    stageFinished: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    now_step(val) {
      if (val >= this.maximum_step) {
        this.$emit("close");
        this.resetNowStep();
      }
    }
  },
  mounted() {
    this.setNowStep(0);
  },
  methods: {
    ...mapActions("Favor", ["setNowStep"]),
    next() {
      console.log("FavorContainer", this.stage, this.now_step);
      this.stepNext();
    }
  }
};
</script>

<style scoped></style>
