<template>
  <div>
    <h3></h3>
    <p>
      沒問題了？那就趕緊出發吧！別讓小桃等太久了！
    </p>
    <div class="position-relative">
      <div class="select-user cha-circle cha-circle-s circle" v-for="row in ready" :key="row.id">
        <img v-bind:src="getAvatar(row)" v-bind:alt="user.nickname" />
      </div>
    </div>

    <button class="btn btn-block btn-primary btn-sm" @click="doSetReady">開始</button>
    <!-- <button class="btn btn-block btn-primary btn-sm" @click="go">go</button> -->
  </div>
</template>

<script>
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import StageFromMixin from "../../Mixins/StageFromMixin";
import UserFromVuex from "../../Mixins/UserFromVuex";
import { mapActions, mapGetters } from "vuex";
import Avatar from "../../Mixins/Avatar";
import { getDeviceMotionPermission } from "@/utils/Motion";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import ModalMixin from "@/Mixins/ModalMixin";

export default {
  name: "ghostRuleEnd",
  mixins: [WebSocketMixin, StageFromMixin, UserFromVuex, TeamFromVuex, ModalMixin, Avatar],
  computed: {
    ...mapGetters("Ghost", ["ready"]),
    avatar() {
      if (this.user.avatar.includes("default_avatar.jpg")) return this.character.picture;
      return this.user.avatar;
    }
  },
  methods: {
    ...mapActions("Ghost", ["startGhost", "sendReady", "startGhost"]),
    doSetReady() {
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人都在線上才能開始遊戲");
        return;
      }
      let user = { avatar: this.avatar };
      this.character.user = user;
      this.sendReady({
        character: this.character
      });
    },
    go() {
      getDeviceMotionPermission();
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人都在線上才能開始遊戲");
        return;
      }
      this.startGhost();
    }
  }
};
</script>

<style scoped lang="scss">
.select-user {
  position: absolute;
  top: -20px;
  /*right:0;*/
  @for $i from 1 through 8 {
    &:nth-child(#{$i}) {
      right: $i * 20-20px;
    }
  }
}
</style>
