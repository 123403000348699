<template>
  <div class="mask mask-dark d-flex justify-content-center align-items-center" @click="areYouBailan">
    <div class="position-relative">
      <img src="/img/bailan-win.png" alt="" />
      <img class="position-absolute favorite-text" src="/img/favorite-bailan-text.png" alt="" />
    </div>
  </div>
</template>

<script>
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import { sendMessage } from "@/utils/WebSocket";
import UserFromVuex from "@/Mixins/UserFromVuex";
import ModalMixin from "@/Mixins/ModalMixin";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "favorBailan",
  mixins: [TeamFromVuex, UserFromVuex, ModalMixin, HowlAudio],
  props: {
    winner: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  mounted() {
    if (this?.character_map && this.character_map.filter(d => d.character.name == "白爛").length > 0) {
      var audio = this.makeAudio("/audio/favor-bailan.mp3");
      audio.play();
      this.timeout = setTimeout(() => {
        if (this.character?.name == "白爛") {
          this.BailanAbility();
        } else {
          this.showModal("我們不要理白爛了");
        }
        this.$emit("next");
      }, 30000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(this.timeout);
      });
    }
  },
  methods: {
    BailanAbility() {
      let data = {
        token: this.token,
        method: "BailanAbility"
      };
      sendMessage(data);
    },
    areYouBailan() {
      if (this.character?.name == "白爛") {
        this.BailanAbility();
        this.$emit("next");
      } else {
        this.showModal("請等白爛點擊畫面");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.mask {
  .position-relative {
    width: 80vw;
    img {
      max-width: 100%;
    }
    img:not(.favorite-text) {
      width: 100%;
    }
  }
}
.favorite-text {
  left: 0;
  bottom: 30px;
}
</style>
