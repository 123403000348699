<template>
  <div>
    <template v-if="getWinner">
      <favorMomo
        @next="nextWinner"
        :defaultName="getWinner.character.name"
        :defaultNickname="getWinner.user.nickname"
      ></favorMomo>
    </template>
    <div class="position-relative full-page fighting-bg">
      <div class="dating-title">格鬥高手是誰</div>
      <div class="result-list text-center">
        <ul class="list-unstyled rank-list font-14">
          <li
            class="d-flex align-items-center"
            :class="{ winner: index == 0 }"
            v-for="(row, index) in score_list"
            :key="row.user.id"
          >
            <div class="mr-2">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="cha-circle circle mr-3">
              <img v-bind:src="showAvatar(row.user.avatar)" alt="" />
            </div>
            <div class="team-name text-truncate mr-3 text-left">{{ row.character.name }}- {{ row.user.nickname }}</div>
            <div>
              <div>你積了{{ row.score }}點</div>
            </div>
          </li>
        </ul>
        <div class="mt-3 mb-3打架排行榜頁面按鈕改動">
          <a
            role="button"
            class="btn btn-primary btn-block mb-3 text-white"
            v-if="flag('fighting_over') == false"
            @click="fightingAgain"
            >再打一次</a
          >
        </div>
        <div class="mt-3" v-if="flag('fighting_over') == false && GameTimes >= 2">
          <a role="button" class="btn btn-primary btn-block mb-3 text-white" @click="finishFighting"
            >結束格鬥結算分數</a
          >
        </div>
        <div class="mt-3" v-if="flag('fighting_over')">
          <a role="button" class="btn btn-primary btn-block mb-3 text-white" @click="leave">離開</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../Mixins/Avatar";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import { sendMessage } from "../utils/WebSocket";
import UserFromVuex from "../Mixins/UserFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import FavorFromMixin from "../Mixins/FavorFromMixin";
import { mapActions, mapGetters } from "vuex";
import favorMomo from "@/views/part/favorMomo";
import ModalMixin from "@/Mixins/ModalMixin";

export default {
  name: "fightingResult",
  mixins: [Avatar, TeamFromVuex, UserFromVuex, StageFromMixin, WebSocketMixin, FavorFromMixin,ModalMixin],
  components: {
    favorMomo
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Fight", ["game_times", "winner_log", "winners", "score_list_in_local"]),
    score_list() {
      if (this.flag("fighting_over")) {
        return this.score_list_in_local;
      }
      let scores = this?.team_payload?.fighting?.score;
      if (!scores) return [];

      scores = Object.keys(scores)
        .map(key => {
          return {
            user_id: key,
            user: this.team.member.find(d => d.user_id == parseInt(key))?.user,
            character: this.character_map.find(d => d.user_id == key)?.character,
            score: scores[key]
          };
        })
        .sort((a, b) => b?.score - a?.score);

      // this.members.filter(d=>{
      //   return !scores.find(d2=>d2.user_id == d.user_id)
      // }).forEach(d=>{
      //   scores.append({
      //     user_id: d.user_id,
      //     user: d.user,
      //     character: this.character_map.find(d => d.user_id == key)?.character,
      //     score: scores[key]
      //   })
      // })

      this.setScoreList(scores);

      return scores;
    },
    getWinner() {
      if (this.flag("fighting_over")) return null;
      return this.winners?.[this.showWinnerIndex];
    },
    winner() {
      return this.score_list?.[0];
    },
    GameTimes() {
      return this.team_payload?.fighting?.incrementGameTimes ?? 0;
    }
  },
  data() {
    return {
      stage: {
        stage_id: 4
      },
      fighting_bonus: false,
      showWinnerIndex: 0
    };
  },
  methods: {
    ...mapActions("Fight", ["showWinner", "setWinners", "setScoreList"]),
    nextWinner() {
      this.showWinnerIndex += 1;

      if (this.showWinnerIndex >= this.winners.length) {
        if (this.isLeader) {
          this.setFlag({ key: "fighting_over", status: true });
          this.setFlag({ key: "stage_4_fightingFinish", status: true });
        } else{
          this.showModal("隊長才能結束 請等隊長看完");
          return ;
        }

        const data = {
          method: "EnterStage",
          token: this.token,
          location: {
            link: "park228"
          }
        };
        sendMessage(data);
      }
    },
    finishFighting() {
      if (!this.isLeader) {
        this.showModal("隊長才能結束 請找隊長算帳");
        return;
      }

      if (confirm("確定要結束格鬥嗎？ 之後就不能再打了唷") == false) {
        return;
      }
      // let winner = null;
      let max_score = Math.max(...this.score_list.map(d => d.score));
      let winners = this.score_list.filter(d => d.score == max_score);
      this.setFlag({ key: "stage_4_fightingWinner", status: winners[0]?.character.name });
      this.setWinners(winners);

      winners.forEach(winner => {
        this.addScoreByRate({
          user_id: parseInt(winner.user_id),
          characterName: winner.character,
          score: 20,
          isMoveStage: true
        });
      });
      this.fighting_bonus = true;
      // if(this.flag('stage_4_fightingFinish') ==false){
      //
      // }
      // this.setFlag({ key: "stage_4_fightingFinish", status: true });
    },
    fightBonusOver() {
      this.fighting_bonus = false;
    },
    leave() {
      this.$router.replace("park228");
    },
    fightingAgain() {
      // if (!this.isLeader) {
      //   this.showModal("隊長才能開始 請找隊長算帳");
      //   return;
      // }
      const data = {
        method: "FightingAgain",
        token: this.token
      };
      sendMessage(data);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.dating-title {
  background: rgba(27, 167, 233, 0.8);
  color: #fff;
  font-size: 16px;
  padding: 96px 0 14px;
  text-align: center;
}

.result-list {
  padding: 30px 30px 0;

  li.winner {
    // background: #ffbc3c;
    // color: #fff;

    > div:first-child {
      span {
        display: block;
        transform: translateX(-5px) rotate(10deg) scale(3);
        font-weight: bold;
      }
    }
  }
}
</style>
