<template>
  <div>
    <h3 class="">教學影片</h3>
    <p class="text-white">
      還是不明白嗎？那來看一下示範影片吧！<br />Step1. 逛街約會吧<br />Step2. 答案手牌<br />Step3. 說話的藝術
    </p>
    <div>
      <vimeo vimeo_key="datingDemo" dataWidth="260"></vimeo>
    </div>
  </div>
</template>

<script>
import vimeo from "./vimeo.vue";
export default {
  name: "datingRuleVideo",
  components: {
    vimeo
  }
};
</script>

<style scoped></style>
