<template>
  <div class="position-relative">
    <!--<div class="position-absolute play-block" @click.stop="videoControl"></div>-->
    <div class="igstory-wrap" v-bind:class="{ active: videoPaused }">
      <img class="cover-fit" @click.stop="videoControl" v-show="videoPaused" :src="'/img/' + video.poster" alt="" />
      <!--<a class="skip-btn position-absolute text-white" @click="skipVideo" v-show="!videoPaused">Skip</a>-->
      <div ref="vimeo" :data-vimeo-url="vimeo_url" :data-vimeo-width="width" :id="element_id" allowfullscreen></div>
    </div>
  </div>
</template>

<script>
import StageFromMixin from "../../Mixins/StageFromMixin";
import { mapGetters, mapActions } from "vuex";
import player from "@vimeo/player";

export default {
  name: "igStory",
  mixins: [StageFromMixin],
  data() {
    return {
      width: 300,
      igVideo: null,
      videoElement: null,
      videoEnd: false,
      videoPaused: true
    };
  },
  computed: {
    ...mapGetters("Video", ["getVideoPath", "getVimeoId"]),
    element_id() {
      return "vimeo-" + this.video.src;
    },
    vimeo_url() {
      return this.getVimeoId(this.video.src);
    }
  },
  props: {
    video: null,
    autoplay: {
      default: false
    },
    fullscreen: {
      default: true
    }
  },
  watch: {},
  methods: {
    ...mapActions("Video", ["fakeFullScreen"]),
    videoControl() {
      this.loadVideo();
      this.igVideo.play();
      this.fakeFullScreen({ status: true, elem: this.igVideo });
      this.videoPaused = false;
    },
    async skipVideo() {
      if (this.igVideo) {
        let duration = await this.igVideo.getDuration();
        this.igVideo.setCurrentTime(duration);
      }
      this.videoEnd = true;
      this.videoPaused = true;
      this.fakeFullScreen({ status: false, elem: this.igVideo });

      this.setFlag({ key: this.video.nextPhase, status: true });
    },
    loadVideo() {
      this.igVideo = new player(this.element_id, {
        autoplay: this.autoplay,
        fullscreen: this.fullscreen
      });
      this.igVideo.on("play", () => {
        // console.log("play")
        this.videoPaused = false;
      });
      this.igVideo.on("pause", () => {
        // console.log("pause")
        this.videoEnd = true;
        this.videoPaused = true;
        this.fakeFullScreen({ status: false, elem: this.igVideo });
      });
      this.igVideo.on("ended", () => {
        // document.exitFullscreen();
        this.videoEnd = true;
        this.videoPaused = true;
        if (this.flag(this.video.stageFlag) == false) {
          this.$emit("goGame");
          this.setFlag({ key: this.video.stageFlag, status: true });
        }
        // this.fakeFullScreen({status:false,elem:this.igVideo});
        // this.setFlag({ key: this.video.nextPhase, status: true });
      });
    }
  },
  mounted() {
    this.width = window.innerWidth;
    this.$nextTick(() => {
      this.loadVideo();
    });
  },
  destroyed() {}
};
</script>

<style scoped lang="scss">
video {
  width: 100%;
  height: auto;
}

.igstory-wrap.active {
  height: 100vw;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
/*.play-block {*/
/*left: 0;*/
/*top: 0;*/
/*right: 0;*/
/*bottom: 0;*/
/*z-index: 9;*/
/*}*/
</style>
