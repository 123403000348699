export default {
  data() {
    return {
      showProgressUpdate: false,
      showProgressUpdateFlags: []
    };
  },
  computed: {
    checkFlagCount() {
      return this.showProgressUpdateFlags.map(d => this.flag(d)).filter(d => d).length;
    }
  },
  watch: {
    checkFlagCount(val, oldVal) {
      if (val != oldVal) this.showProgressUpdate = true;
    }
  }
};
