<template>
  <div class="position-relative" @click="cdClick">
    <findPlace v-if="findPlaceShow"></findPlace>
    <svg class="btn-icon-28 hint-btn" @click.stop="hintBtnClick">
      <use xlink:href="#icon-tip" class="fill-blue active"></use>
    </svg>
    <img class="img-fluid" src="/img/csh-find2.jpg" alt="" />
    <img
      v-if="flag('stage_3_phase2_find2_clear')"
      src="/img/csh-find-2-p.png"
      class="position-absolute road-person"
      alt=""
      @click="showNextMsg"
    />
    <img
      v-if="flag('stage_3_phase2_find2_p1')"
      class="position-absolute ans-circle"
      src="/img/csh-find2-1.png"
      alt=""
    />
    <img
      v-if="flag('stage_3_phase2_find2_p2')"
      class="position-absolute ans-circle"
      src="/img/csh-find2-2.png"
      alt=""
    />
    <img
      v-if="flag('stage_3_phase2_find2_p3')"
      class="position-absolute ans-circle"
      src="/img/csh-find2-3.png"
      alt=""
    />
    <svg
      class="position-absolute ans-circle click-detect"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 750 930"
      style="enable-background:new 0 0 750 930;"
      xml:space="preserve"
    >
      <g class="click-area" id="" @click.stop.prevent="clickArea(0)">
        <path d="M499,187c23-1.7,56,33,48,72s-22,53-43,55s-51-38-49-64S472,189,499,187z" />
      </g>
      <g class="click-area" id="" @click.stop.prevent="clickArea(1)">
        <path d="M264,297c32.7,4.8,159,18,158,59s-140,34-158,35s-191,0-197-35S175,284,264,297z" />
      </g>
      <g class="click-area" id="" @click.stop.prevent="clickArea(2)">
        <path d="M109,513c21.5,4.2,74,68,21,110S18,587,22,577S37,499,109,513z" />
      </g>
    </svg>
  </div>
</template>

<script>
import StageFromMixin from "../../Mixins/StageFromMixin";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import UserFromVuex from "../../Mixins/UserFromVuex";
import findPlace from "@/views/part/findPlace";
import delayEmit from "@/Mixins/delayEmit";
import FindMixin from "@/Mixins/FindMixin";

export default {
  name: "zshFind2",
  components: {
    findPlace
  },
  mixins: [StageFromMixin, TeamFromVuex, UserFromVuex, delayEmit, FindMixin],
  props: {
    step: {
      default: 0
    }
  },
  data() {
    return {
      findPlaceShow: false,
      canClick: true,
      // correctCount: 0,
      detectArea0: false,
      detectArea1: false,
      detectArea2: false
      // detectArea: [ false, false, false]
    };
  },
  computed: {
    correctCount: function() {
      let count = 0;
      if (this.flag("stage_3_phase2_find2_p1")) {
        count++;
      }
      if (this.flag("stage_3_phase2_find2_p2")) {
        count++;
      }
      if (this.flag("stage_3_phase2_find2_p3")) {
        count++;
      }
      return count;
    }
  },
  methods: {
    cdClick() {
      if (!this.canClick) return;

      if (!this.flag("stage_3_phase2_find2_clear")) {
        this.findPlaceShow = true;
        this.canClick = false;
        setTimeout(() => {
          this.canClick = true;
          this.findPlaceShow = false;
        }, 5000);
      }
    },
    clickArea(area) {
      if (!this.canClick) return;
      this.findPlaceShow = false;
      this.canClick = true;
      switch (area) {
        case 0:
          if (!this.flag("stage_3_phase2_find2_p1")) {
            this.hideFindPlaceShow();
            this.delayEmit("showfavor");

            this.setFlag({ key: "stage_3_phase2_find2_p1", status: true });
          }
          break;
        case 1:
          if (!this.flag("stage_3_phase2_find2_p2")) {
            this.hideFindPlaceShow();
            this.delayEmit("showfavor");

            this.setFlag({ key: "stage_3_phase2_find2_p2", status: true });
          }
          break;
        case 2:
          if (!this.flag("stage_3_phase2_find2_p3")) {
            this.hideFindPlaceShow();
            this.delayEmit("showfavor");
            this.setFlag({ key: "stage_3_phase2_find2_p3", status: true });
          }
          break;
      }
      if (this.correctCount >= 3) {
        this.setFlag({ key: "stage_3_phase2_find2_clear", status: true });
      }
    },
    showNextMsg() {
      this.setFlag({ key: "stage_3_phase2_find3", status: true });
    },
    hintBtnClick() {
      this.$emit("hintBtnClick", "phase2Find2", this.step);
    }
  }
};
</script>

<style scoped lang="scss">
.hint-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
}
.road-person {
  left: 10vw;
  top: 10vw;
  height: 40vw;
  z-index: 99;
}

.click-area {
  fill: rgba(255, 255, 255, 0);
}

.ans-circle {
  width: 100%;
  left: 0;
  top: 0;
  /*pointer-events: none;*/
}

.click-detect {
  z-index: 10;
}
</style>
