import { render, staticRenderFns } from "./fightingBystand.vue?vue&type=template&id=49c5481d&scoped=true&"
import script from "./fightingBystand.vue?vue&type=script&lang=js&"
export * from "./fightingBystand.vue?vue&type=script&lang=js&"
import style0 from "./fightingBystand.vue?vue&type=style&index=0&id=49c5481d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c5481d",
  null
  
)

export default component.exports