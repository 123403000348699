<template>
  <div>
    <div class="embed-responsive embed-responsive-1by1">
      <div class="embed-responsive-item">
        <img class="contain-fit" src="/img/loading.svg" alt="" v-if="uploading" />
        <slider :slides="imagePackageSetting" :key="check" v-else></slider>
      </div>
    </div>
    <slot></slot>
    <div class="unlock-block position-relative">
      <!-- <a role="button" class="hint-20">?</a> -->
      <div class="unblock-inside d-flex position-relative justify-content-center">
        <div v-if="this.flag(this.photo.finish)" class="divide-line"></div>
        <a @click="toggleModalMiniMap" class="d-flex align-items-center justify-content-center">
          <div>
            <img class="" src="/img/location-see.svg" alt="" />
            <div class="mt-2 icon-intro">查看<br />建議拍照地點</div>
          </div>
        </a>
        <a @click="fileClick" :class="{ disalbed: uploading }" class="d-flex align-items-center justify-content-center">
          <div>
            <img class="" src="/img/icon-upload.svg" alt="" />
            <div class="mt-2 icon-intro">上傳照片</div>
          </div>
        </a>
        <input type="file" name="photo" ref="file" class="d-none" @change="uploadImg($event)" />
      </div>
      <div class="d-flex position-relative justify-content-center py-5">
        <a
          v-if="this.flag(this.photo.finish) && notStage2"
          :class="{ disalbed: !latestPhoto }"
          class="d-flex flex-column align-items-center justify-content-center"
          @click="finishStage"
        >
          <div class="text-center">
            <img class="" src="/img/icon-finish.svg" alt="" />
            <div class="mt-2 icon-intro">完成關卡</div>
          </div>
        </a>
      </div>
    </div>
    <modalMiniMap
      :flyTo="photo.flyTo"
      :map_config="map_config"
      :defaultUserCenter="photoStage.local"
      :markers="markers"
      :is_show="showMiniMap"
      v-on:toggleModal="toggleModalMiniMap"
    ></modalMiniMap>
  </div>
</template>

<script>
import { teamPhotoUpload } from "../../utils/Api";
import ModalMixin from "../../Mixins/ModalMixin";
import ResultMixin from "../../Mixins/ResultMixin";
import StageFromMixin from "../../Mixins/StageFromMixin";
import modalMiniMap from "@/views/part/modalMiniMap";
import { icon, latLngBounds } from "leaflet";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import slider from "@/views/part/slider";
import EXIF from "@/Mixins/exif-js";

export default {
  name: "photoMission",
  mixins: [ModalMixin, ResultMixin, StageFromMixin],
  props: {
    photo: {
      default: {}
    },
    stage: {
      required: true
    },
    returnToHomeMap:{
      default: true
    }
  },
  computed: {
    ...mapGetters("Stage", ["locations"]),
    photoStage() {
      let temp = this.locations.filter(location => {
        return location.stage == this.stage.stage_id && location.id.includes("photo");
      });

      return temp.length > 0 ? temp[0] : {};
    },
    notStage2() {
      return !this.photo.finish.includes("stage_2");
    },
    markers() {
      return [
        {
          is: "lMarker",
          options: {
            "lat-lng": this.photoStage?.local,
            icon: icon(this.ximenRoute.start.icon)
          }
        }
      ];
    },
    uploading() {
      //非上傳照片的隊員(被動更改上傳狀態)，要設定此狀態的timeout，避免上傳者斷線導致卡住的狀況
      if(this.flag("result_uploading") ){
        if(this.isUploading == false){
          this.isUploading = true;

          this.uploadingTimeout = setTimeout(() => {
            if(this.flag("result_uploading")){
              this.setFlag({ key: "result_uploading", status: false });
              this.isUploading = false;
              alert("照片上傳失敗 請重新嘗試")
            }
          }, 23000);//伺服器timeout為20秒，若23秒後沒有被通知timeout則直接判定timeout，並且更新result_uploading同步狀態
        }
      }else{
        this.isUploading = false;
        if(this.uploadingTimeout!=null){
          clearTimeout(this.uploadingTimeout);
        }
      }
      return this.flag("result_uploading");
    },
    check() {
      return JSON.stringify(this.photos);
    },
    latestPhoto() {
      let stage_id = this.stage.stage_id || this.stage;
      return this.photos?.[stage_id.toString()] || this.photos?.[stage_id];
    },
    test() {
      let stage_id = this?.stage?.stage_id || this.stage;
      return this.photos[stage_id.toString()] || this.photos[stage_id];
    },
    imagePackageSetting() {
      if (this.latestPhoto) {
        if (this.imagePackage.length <= 1) {
          //如果只有預設圖，就加一張，否則覆蓋最後一張
          this.imagePackage.unshift(this.latestPhoto);
        } else {
          this.imagePackage[0] = this.latestPhoto;
        }
      }
      return {
        width: 375,
        height: 375,
        imgs: this.imagePackage
      };
    }
  },
  components: { modalMiniMap, slider },
  watch: {
    photos(val, oldval) {
      let val_check = JSON.stringify(val);
      let oldval_check = JSON.stringify(oldval);
      if (val_check !== oldval_check) {
        let stage_id = this.stage.stage_id || this.stage;
        this.temp = this.stagePhoto(stage_id);
      }
    }
  },
  mounted() {
    this.image = "img/" + this.photo.img;
  },
  data() {
    return {
      image: null,
      isUploading: false,
      uploadingTimeout: null,
      uploadResult: null,
      temp: null,
      map_config: {
        // zoom: 18,
        minZoom: 16,
        center: [25.045262, 121.504614],
        bounds: latLngBounds([
          [25.059609, 121.500229],
          [25.021118, 121.529439]
        ]),
        maxBounds: latLngBounds([
          [25.059609, 121.500229],
          [25.021118, 121.529439]
        ]),
        options: {
          zoomSnap: 0.5
        }
      },
      ximenRoute: {
        start: {
          icon: {
            iconUrl: "/img/pin-camara.png",
            iconSize: [70, 70],
            iconAnchor: [35, 70]
          }
        },
        end: {
          icon: {
            iconUrl: "/img/ximen-end.png",
            iconSize: [26, 42],
            iconAnchor: [13, 42]
          }
        },
        polyline: {
          latlngs: [
            [25.04293, 121.507765],
            [25.043365, 121.507854],
            [25.043953, 121.505091],
            [25.045074, 121.5054],
            [25.045262, 121.504614]
          ],
          color: "#d9006c"
        }
      },
      showMiniMap: false,
      imagePackage: [this.photo.img]
    };
  },
  methods: {
    fileClick() {
      if (this.uploading) return;
      this.$refs.file.click();
    },
    finishStage: function() {
      if (!this.latestPhoto) {
        return;
      }
      if (this.$listeners.final) {
        this.$emit("final");
      }

      if (this.flag(this.photo.flag) && this.returnToHomeMap) {
        this.$router.replace("homeMap");
        return;
      }

      this.setFlag({ key: this.photo.flag, status: true });
      this.$emit("showStageFinishedFavor");
      // this.$router.replace("homeMap");
    },
    uploadImg(e) {
      //上传图片
      // this.option.img

      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("圖片類型必須為 gif,jpeg,jpg,png,bmp");
        return false;
      }
      // this.upload(file);
      var reader = new FileReader();
      var orientation = 0;
      EXIF.getData(file, function() {
        //取得照片拍攝的角度
        orientation = EXIF.getTag(this, "Orientation");
      });

      reader.onload = e => {
        let data = e.target.result;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        }
        var rotateAngle = 0;
        switch (orientation) {
          case 3:
            rotateAngle = 180;
            break;
          case 6:
            rotateAngle = 90;
            break;
          case 8:
            rotateAngle = -90;
            break;
          default:
            rotateAngle = 0;
            break;
        }
        console.log("orientation: ", orientation);
        console.log("file.size: ", file.size);

        this.blobCompress(new Blob([e.target.result]), rotateAngle).then(file => {
          console.log("new file", file);
          this.upload(file);
        });

        this.image = data;
        this.$refs.file.value = "";
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },

    upload(data) {
      this.isUploading = true;
      this.setFlag({ key: "result_uploading", status: true });
      var formData = new FormData();

      formData.append("photo", data);
      formData.append("stage", this.stage.stage_id);

      console.log("photo upload start!!!!!");
      teamPhotoUpload(formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        timeout: 20000
      })
        .then(response => {
          console.log("check response data: ", response.data);
          console.log("photo upload success!!!!!");
          this.uploadResult = response.data;
          this.image = this.uploadResult?.data;
          this.syncPhoto({
            photo: this.image,
            stage: this.stage
          });
          if (this.$listeners.next) {
            this.$emit("next");
          }
          if (this.photo.flag == "stage_5_clear") {
            this.setFlag({ key: "stage_5_uploaded", status: true });
          }
          this.isUploading = false;
          this.setFlag({ key: "result_uploading", status: false });
        })
        .catch(e => {
          this.showModal(e.message);
          this.isUploading = false;
          this.setFlag({ key: "result_uploading", status: false });
        });
    },
    //壓縮上傳圖片
    blobCompress(blob, rotateAngle) {
      return new Promise(resolve => {
        //準備canvas
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = 1200;
        canvas.height = 1200;

        //blob轉成DataURL
        let fr = new FileReader();
        fr.readAsDataURL(blob);
        fr.onload = function(e) {
          let data = {
            canvas: canvas,
            context: context,
            dataURL: e.target.result,
            rotateAngle: rotateAngle
          };
          resolve(data);
        };
      })
        .then(data => {
          //因為進入img.onload之後都會抓不到this，因此在此先寫入data
          if (typeof this.photo.compositeImg != "undefined") {
            data.compositeImgURL = this.photo.compositeImg;
          }

          // dataURL轉成img
          return new Promise(resolve => {
            let img = new Image();
            img.src = data.dataURL;
            img.onload = function() {
              let imgWidth = img.width; //圖片寬度
              let imgHeight = img.height; //圖片高度
              let dWidth = data.canvas.width;
              let dHeight = data.canvas.height;
              let sx, sy, sWidth, sHeight; //裁切座標|裁切寬高

              //裁切照片置中，成為正方形
              let photo_Horizontal = imgWidth > imgHeight ? true : false;
              if (imgWidth > imgHeight) {
                sx = (imgWidth - imgHeight) / 2;
                sy = 0;
                sWidth = imgHeight;
                sHeight = imgHeight;
              } else {
                sy = (imgHeight - imgWidth) / 2;
                sx = 0;
                sWidth = imgWidth;
                sHeight = imgWidth;
              }
              data.context.save();
              data.context.translate(600, 600); //讓旋轉軸心設定在中央
              //data.context.rotate((data.rotateAngle * Math.PI) / 180);
              data.context.drawImage(img, sx, sy, sWidth, sHeight, -600, -600, dWidth, dHeight);
              data.context.restore();
              //在canvas重新繪製較小大小的圖片
              //image參數是來源影像物件，(sx, sy)代表在來源影像中以(sx, sy)座標點作為切割的起始點，
              //sWidth和sHeight代表切割寬和高，(dx, dy)代表放到畫布上的座標點，dWidth和dHeight代表縮放影像至指定的寬和高.
              resolve(data);
            };
          });
        })
        .then(function(data) {
          return new Promise(resolve => {
            if (typeof data.compositeImgURL != "undefined") {
              let compositeImg = new Image();
              compositeImg.src = "img/" + data.compositeImgURL;
              compositeImg.onload = function() {
                data.context.drawImage(compositeImg, 0, 0, 1200, 1200);
                resolve(data);
              };
            } else {
              resolve(data);
            }
          });
        })
        .then(function(data) {
          // 圖片壓縮:canvas轉為blob
          let canvasToBlob = () => {
            return new Promise(resolve => {
              data.canvas.toBlob(
                blob => {
                  resolve(new File([blob], "new file"));
                },
                "image/jpeg",
                0.8
              );
            });
          };
          return canvasToBlob();
        });
    },
    toggleModalMiniMap() {
      this.showMiniMap = !this.showMiniMap;
    },
    packageSetting() {
      if (this.imagePackage.length === 1) {
        this.imagePackage = this.latestPhoto.concat(this.imagePackage);
      } else {
        this.imagePackage.unshift(this.latestPhoto[0]);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ghost-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  img {
    object-position: center top;
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
