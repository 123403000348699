<template>
  <div class="text-white position-absolute hint-text">等待隊員選擇中..</div>
</template>

<script>
export default {
  name: "teamWating",
  props: {
    hint: {
      default: "等待隊員選擇中..",
      type: String
    }
  }
};
</script>

<style scoped></style>
