<template>
  <div class="position-relative" @click="switchChord">
    <div class="position-absolute chord">
      <template v-for="(chordImg, index) in chord">
        <img v-if="index == chordIndex" :key="index" :src="'/img/' + chordImg.pic" alt="" />
      </template>
    </div>
    <img class="img-fluid" src="/img/fingerboard.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "fingerkeyboard",
  data() {
    return {
      chordTimer: null,
      canPlayGuitar: true,
      chordIndex: 0,
      chord: [
        {
          pic: "g-c.png",
          sound: "g-C.mp3"
        },
        {
          pic: "g-dm7.png",
          sound: "g-Dm7.mp3"
        },
        {
          pic: "g-em.png",
          sound: "g-Em.mp3"
        },
        {
          pic: "g-f7m.png",
          sound: "g-f7m.mp3"
        }
      ]
    };
  },
  methods: {
    switchChord: function() {
      if (this.canPlayGuitar) {
        this.chordTimer = setTimeout(() => {
          this.disableGuitarPlay();
        }, 1500);
        let cordLength = this.chord.length;
        this.chordIndex = Math.floor(Math.random() * cordLength);
        this.canPlayGuitar = false;
        let music = this.chord[this.chordIndex].sound;
        let audio = new Audio("/audio/" + music);
        audio.play();
      }
    },
    disableGuitarPlay: function() {
      this.canPlayGuitar = true;
    }
  }
};
</script>

<style scoped lang="scss">
.chord {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  img {
    width: 100%;
  }
}
</style>
