var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch:tap.self",value:(
    function () {
      _vm.slideTo(_vm.index + 1);
    }
  ),expression:"\n    () => {\n      slideTo(index + 1);\n    }\n  ",arg:"tap",modifiers:{"self":true}},{name:"touch",rawName:"v-touch:swipe.right.self",value:(
    function () {
      _vm.slideTo(_vm.index - 1);
    }
  ),expression:"\n    () => {\n      slideTo(index - 1);\n    }\n  ",arg:"swipe",modifiers:{"right":true,"self":true}},{name:"touch",rawName:"v-touch:swipe.left.self",value:(
    function () {
      _vm.slideTo(_vm.index + 1);
    }
  ),expression:"\n    () => {\n      slideTo(index + 1);\n    }\n  ",arg:"swipe",modifiers:{"left":true,"self":true}}],staticClass:"rule-panel position-absolute"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(
      function () {
        _vm.slideTo(_vm.index + 1);
      }
    ),expression:"\n      () => {\n        slideTo(index + 1);\n      }\n    ",arg:"tap"}],ref:"infoBox",staticClass:"slide-wrap overflow-hidden position-relative"},[_c('ul',{staticClass:"list-unstyled rule-slides d-flex position-absolute",style:({ left: _vm.slideToOffset })},_vm._l((_vm.rules),function(rule,key){return _c('li',{key:key},[(rule.component)?[_c(rule.component,{tag:"div"})]:[_c('div',{domProps:{"innerHTML":_vm._s(rule.title)}}),_c('div',{domProps:{"innerHTML":_vm._s(rule.content)}})]],2)}),0)]),_c('div',{staticClass:"slide-points d-flex justify-content-between"},[_c('a',{class:{ disabled: _vm.index == 0 },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.slideTo(_vm.index - 1)}}},[_c('img',{staticClass:"white-circle",attrs:{"src":require("@/assets/images/icon-backward.svg"),"alt":""}})]),_c('ul',{staticClass:"list-unstyled d-flex align-items-center m-0"},_vm._l((_vm.rules),function(rule,key){return _c('li',{key:key,class:{ active: key == _vm.index },on:{"click":function($event){return _vm.slideTo(key)}}})}),0),_c('a',{class:{ disabled: _vm.index == _vm.rules.length - 1 },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.slideTo(_vm.index + 1)}}},[_c('img',{staticClass:"white-circle",attrs:{"src":require("@/assets/images/icon-forward.svg"),"alt":""}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }