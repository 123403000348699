export default {
  methods: {
    getRandomPeople: function() {
      const character = ["小彥", "小桃", "小希", "春哥", "白爛", "赤木", "花輪", "胖子", "壞主意"];
      return character[Math.floor(Math.random() * Math.floor(character.length))];
    },
    getRandomLike: function(baseCount = 20) {
      return baseCount + Math.floor(Math.random() * Math.floor(16));
    }
  }
};
