<template>
  <div class="mask mask-dark ">
    <div class="full-panel overflow-auto">
      <div class="position-relative">
        <div class="team-card">
          <header class="position-relative shi-header text-center">
            <div class="count-down position-absolute">{{ countDownNumber }}</div>
            <h3>閨蜜的祝福</h3>
            <div class="shi-sub">小希答對囉，要幫誰助攻呢？</div>
            <img class="position-absolute" src="/img/favorite-shi.png" alt="" />
          </header>
          <ul class="list-unstyled m-0 team-members d-flex flex-column">
            <li class="d-flex" v-for="member in members_without_self" :key="member.user.id">
              <div class="d-flex justify-content-center shi-check">
                <img
                  v-if="shi_select_who == member.user.id"
                  @click="checkMember(member.user.id)"
                  src="/img/list-check.svg"
                  alt=""
                />
                <div @click="checkMember(member.user.id)" v-else class="uncheck"></div>
              </div>
              <div class="shi-avatar">
                <img :src="getAvatar(member)" alt="" />
              </div>
              <div class="shi-name d-flex align-content-center">
                {{ member.user.nickname }}
              </div>
            </li>
          </ul>
          <footer>
            <button v-if="is_shi" class="btn-white btn btn-block" @click="submit">
              確定
            </button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import CharacterFromVuex from "../../Mixins/CharacterFromVuex";
import Avatar from "../../Mixins/Avatar";
import { sendMessage } from "../../utils/WebSocket";
import UserFromVuex from "../../Mixins/UserFromVuex";
import ModalMixin from "../../Mixins/ModalMixin";
import FavorFromMixin from "../../Mixins/FavorFromMixin";
import { mapActions } from "vuex";

export default {
  name: "favorShi",
  mixins: [TeamFromVuex, CharacterFromVuex, Avatar, UserFromVuex, ModalMixin, FavorFromMixin],
  data() {
    return {
      timeout: null,
      countDownNumber: 30
    };
  },
  props: {
    stage: null,
    step: {
      default: 1
    },
    score: {
      default: 10000
    }
  },
  computed: {
    is_shi() {
      return this?.character?.name == "小希";
    },
    members_without_self() {
      const user = this.character_map.find(d => d.character.name == "小希");
      return this.members.filter(d => d.user_id != user.user_id);
    },
    selectCharacterName() {
      return this.character_map.find(d => d.user_id == this.shi_select_who)?.character?.name;
    }
  },
  mounted: function() {
    this.fakeFullScreen({ status: true });
    if (this?.character_map && this.character_map.filter(d => d.character.name == "小希").length == 0) {
      this.$emit("next");
    }
    if (!this.is_shi) {
      this.timeout = setTimeout(() => {
        this.$emit("next");
      }, 30000);
    }
    this.countDown();
  },
  beforeDestroy() {
    this.fakeFullScreen({ status: false });
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions("Favor", ["shi_do_select"]),
    ...mapActions("Video", ["fakeFullScreen"]),
    submit() {
      if (this.shi_select_who === null) {
        this.showModal("沒有選人助攻歐");
        return;
      }
      this.addScoreByRate({
        score: 5,
        user_id: this.shi_select_who,
        isMoveStage: false,
        hint_key: "favorShi"
      });
      let data = {
        token: this.token,
        method: "ShiSelected",
        bonus_to_who: this.shi_select_who,
        stage: this.stage,
        step: this.step,
        score: this.score
      };
      sendMessage(data);

      // this.$emit('next');
    },
    checkMember(id) {
      if (this.is_shi) {
        this.shi_do_select({
          token: this.token,
          stage: this.stage,
          step: this.step,
          who: id
        });
      }
    },
    countDown() {
      let timeoutId = window.setInterval(() => {
        this.countDownNumber--;
        if (this.countDownNumber <= 0) {
          window.clearTimeout(timeoutId);
        }
      }, 1000);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.shi-header {
  padding: 47px 0 30px;
  border-radius: 26px 26px 0 0;

  h3 {
    font-size: 24px;
    font-weight: bold;
    position: relative;
    z-index: 2;
  }

  .shi-sub {
    font-size: 14px;
    position: relative;
    z-index: 2;
  }

  img {
    right: 0;
    bottom: 0;
    width: 80px;
    height: auto;
  }
}

.team-card {
  overflow: visible;

  footer {
    background: $main-color;
    border-radius: 0 0 26px 26px;

    .btn-white {
      color: $main-color;
    }
  }
}

.team-members {
  overflow: hidden;
  background: $main-color;

  li {
    border: none;
    margin-bottom: 4px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
    background: #fff;
    padding: 0px 17px;

    .shi-check {
      margin-right: 20px;
    }

    .uncheck {
      border: 1px solid #707070;
      width: 25px;
      height: 25px;
    }

    .shi-avatar {
      width: 40px;
      height: 40px;
      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }
  }
}

.count-down {
  top: 0;
  left: 0;
  font-size: 36px;
  font-weight: bold;
  padding: 6px 12px 6px 12px;
}
</style>
