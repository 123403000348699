<template>
  <div>
    <img class="position-absolute photo-find" src="/img/place-find.png" alt="" />
    <div class="position-absolute photo-find time" style="">尋找中 {{ this.time }}</div>
  </div>
</template>

<script>
export default {
  name: "findPlace",

  data() {
    return {
      timer: null,
      time: 5
    };
  },
  mounted() {
    this.timer = setInterval(this.countdown, 1000);
  },
  methods: {
    countdown() {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
.photo-find {
  top: 42%;
}

.time {
  background: #67a9a7;
  border-radius: 10px;
  color: white;
  font-size: 5vw;
  font-weight: bold;
  text-align: center;
  padding: 1vw;
  top: 58%;
  box-shadow: 1vw 1vw 3px rgba(0, 0, 0, 0.7);
}
</style>
