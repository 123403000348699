<template>
  <div class="position-absolute prepare-block text-center">
    <h2 class="text-center mb-4 text-white">{{ title }}</h2>
    <div v-for="(button, index) in btnLink" :key="index" class="position-relative">
      <div class="select-user cha-circle cha-circle-s circle" v-for="row in getUsers(button.users)" :key="row.id">
        <img v-bind:src="getAvatar(row)" v-bind:alt="getName(row)" />
      </div>
      <div v-if="showStartGame(button)">
        <a v-if="button.event" role="button" @click="button.event" class="btn btn-block btn-white mb-3">{{
          button.name
        }}</a>
        <a v-else role="button" @click="$router.replace(button.link)" class="btn btn-block btn-white mb-3">{{
          button.name
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import { mapGetters } from "vuex";
import TeamFromVuex from "@/Mixins/TeamFromVuex";

export default {
  name: "gamePrepare",
  mixins: [Avatar, WebSocketMixin, TeamFromVuex],
  props: {
    title: {
      default: "",
      type: String
    },
    btnLink: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    ...mapGetters("Team", ["isLeader"])
  },
  methods: {
    getAvatar(row) {
      let avatar = row?.user?.avatar ?? "";

      if (avatar?.includes("default")) {
        avatar = row?.character?.picture;
      }
      if (avatar?.includes("profile")) {
        avatar = row?.character?.picture;
      }
      return this.showAvatar(avatar || row?.character?.picture || row?.picture || row?.avatar);
    },
    getName(row) {
      return row?.user?.nickname || row?.character?.name || row?.name;
    },
    getUsers(target) {
      if (Array.isArray(target)) {
        return target;
      }

      return target();
    },
    showStartGame(button) {
      if (button.name === "開始遊戲") {
        return button.event && this.isLeader ? true : false;
      } else {
        return button.event ? true : false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.prepare-block {
  right: 30px;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.select-user {
  position: absolute;
  top: -20px;
  /*right:0;*/
  @for $i from 1 through 8 {
    &:nth-child(#{$i}) {
      right: $i * 20-20px;
    }
  }
}
</style>
