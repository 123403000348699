import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Favor", ["winner", "winner_user", "shi_select_who", "now_step"])
  },
  methods: {
    ...mapActions("Favor", ["setWinner", "stepNext", "setNowStep", "resetNowStep"])
  }
};
