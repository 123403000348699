<template>
  <div class="position-relative overflow-hidden" @click="activeShack">
    <div v-if="!fingerboardShow">
      <img class="img-fluid" src="/img/csh-bg.jpg" alt="" />
      <img
        class="position-absolute zsh-ch ch1"
        src="/img/csh-c1.png"
        :style="transform1"
        @click="()=>{flag('touchable') && movePlayer()}"
        alt=""
      />
      <img class="position-absolute zsh-ch ch4" src="/img/csh-r1.png" :style="transform2" alt="" />
      <img class="position-absolute zsh-ch ch4b" src="/img/csh-r2.png" :style="transform3" alt="" />
      <img class="position-absolute zsh-ch ch4c" src="/img/csh-r3.png" :style="transform4" alt="" />
      <img class="position-absolute zsh-ch ch7" src="/img/csh-c7.png" :style="transform5" alt="" />
      <img class="position-absolute bg-f" src="/img/csh-bg-f.png" alt="" />
      <img
        @click="fingerboardShow = true"
        class="position-absolute guitar-img"
        src="/img/guitar.png"
        alt=""
        v-if="guitarShow"
      />
    </div>
    <fingerkeyboard v-if="fingerboardShow"></fingerkeyboard>
  </div>
</template>

<script>
import { power, setDeviceMotionEvent } from "../../utils/Motion";
import fingerkeyboard from "@/views/part/fingerkeyboard";
import throttle from "lodash.throttle";
import { mapGetters } from "vuex";

export default {
  name: "zhongshanHall",
  components: {
    fingerkeyboard
  },
  computed: {
    ...mapGetters("Stage", ["flag"]),
    transform1() {
      return {
        transform: "translate(-50%, calc(-50% - " + this.move1 + "px)) rotate(" + this.rotate1 + "deg)"
      };
    },
    transform2() {
      return {
        transform: "translate(-50%, calc(-50% - " + this.move2 + "px)) rotate(-" + this.rotate2 + "deg)"
      };
    },
    transform3() {
      return {
        transform: "translate(-50%, calc(-50% - " + this.move3 + "px)) rotate(" + this.rotate3 + "deg)"
      };
    },
    transform4() {
      return {
        transform: "translate(-50%, calc(-50% - " + this.move4 + "px)) rotate(-" + this.rotate4 + "deg)"
      };
    },
    transform5() {
      return {
        transform: "translate(-50%, calc(-50% - " + this.move5 + "px)) rotate(" + this.rotate5 + "deg)"
      };
    }
  },
  data() {
    return {
      isBindDeviceMotionHandler: false,
      guitarShow: false,
      fingerboardShow: false,
      offsetTop: 125,
      shackValue: 20,
      shackTimes: 0,
      maxShack1: false,
      maxShack2: false,
      maxShack3: false,
      rotateMin: 30,
      rotateMax: 45,
      rotate1: 0,
      rotate2: 0,
      rotate3: 0,
      rotate4: 0,
      rotate5: 0,
      moveMin: 30,
      moveMax: 70,
      move1: 0,
      move2: 0,
      move3: 0,
      move4: 0,
      move5: 0
    };
  },
  mounted() {
    this.bindEvent();
  },
  methods: {
    bindEvent() {
      if (!this.isBindDeviceMotionHandler) {
        this.isBindDeviceMotionHandler = true;
        setDeviceMotionEvent(this.deviceMotionHandler, alert);
      }
    },
    doDrag: function(event) {
      let x = event.touches[0].clientX;
      let y = event.touches[0].clientY - this.offsetTop;
      event.target.style.left = x + "px";
      event.target.style.top = y + "px";
    },
    activeShack() {
      this.bindEvent();
    },
    movePlayer: throttle(function() {
      this.shackTimes = this.shackTimes + 1;
      if (this.shackTimes <= 3) {
        this.rotate1 = Math.floor(Math.random() * this.rotateMax) + this.rotateMin;
        this.rotate2 = Math.floor(Math.random() * this.rotateMax) + this.rotateMin;
        this.rotate3 = Math.floor(Math.random() * this.rotateMax) + this.rotateMin;
        this.rotate4 = Math.floor(Math.random() * this.rotateMax) + this.rotateMin;
        this.rotate5 = Math.floor(Math.random() * this.rotateMax) + this.rotateMin;
      } else if (this.shackTimes > 3 && this.shackTimes <= 6) {
        this.move2 = Math.floor(Math.random() * this.moveMin) + this.moveMax;
        this.move3 = Math.floor(Math.random() * this.moveMin) + this.moveMax;
        this.move4 = Math.floor(Math.random() * this.moveMin) + this.moveMax;
      } else {
        this.move1 = Math.floor(Math.random() * this.moveMin) + this.moveMax;
        this.move5 = Math.floor(Math.random() * this.moveMin) + this.moveMax;
        this.guitarShow = true;
      }
    }, 300),
    deviceMotionHandler: function(event) {
      // this.movePlayer();
      let move_power = power(event);
      this.deal(move_power);
    },
    deal(power) {
      if (power < this.shackValue) return;
      this.movePlayer();
      if (!this.maxShack1) {
        this.maxShack1 = true;
        return;
      }
      if (!this.maxShack2) {
        this.maxShack2 = true;
        return;
      }
      if (!this.maxShack3) {
        this.maxShack3 = true;
        // window.removeEventListener("devicemotion", this.deviceMotionHandler);
        return;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("devicemotion", this.deviceMotionHandler);
  }
};
</script>

<style scoped>
.zsh-ch {
  /*transform: translate(-50%, -50%);*/
}

.bg-f {
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}

.ch1 {
  width: 23vw;
  left: 63vw;
  top: 82vw;
  z-index: 6;
  transition: 0.1s;
}

.ch4 {
  width: 18vw;
  left: 43vw;
  top: 78vw;
  z-index: 1;
  transition: 0.1s;
}

.ch4b {
  width: 18vw;
  left: 24vw;
  top: 80vw;
  z-index: 1;
  transition: 0.1s;
}

.ch4c {
  width: 18vw;
  left: 76vw;
  top: 79vw;
  z-index: 1;
  transition: 0.1s;
}

.ch7 {
  width: 23vw;
  left: 39vw;
  top: 82vw;
  z-index: 5;
  transition: 0.1s;
}

.ch4.drop,
.ch4b.drop,
.ch4c.drop {
  top: auto;
  bottom: 0;
}

.guitar-img {
  z-index: 11;
  left: calc(50% - 42px);
  top: calc(60%);
  width: 84px;
}
</style>
