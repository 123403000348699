import {mapGetters} from "vuex";

export default {
  props: {
    defaultWhoTalk: {
      default: "謎之音"
    },
    data: null,
    stage: null,
    hint_key: null,
    stageCharacter: {
      default: ""
    },
    step: {
      default: 0
    },
    unlock_flag: {
      default: null
    },
    input: {
      default: true
    }
  },
  computed: {
    ...mapGetters("User", {
      vuex_user: "user",
      character: "character",
      token: "token"
    }),
    messages() {
      let stage_id = this.stage?.stage_id.toString();
      let step = this.step.toString();
      if (this?.team_payload?.stageMessage?.[stage_id]?.[step]) {
        let list = this.team_payload.stageMessage[stage_id][step];
        list = list.filter(d => {
          if (d.only) return d.only == this.vuex_user.id;
          return true;
        });
        if (this.show_all) {
          return list;
        }
        let list_length = list.length;
        return list.slice(list_length - this.limit, list_length);
      }
      return [];
    }
  },
};
