<template>
  <div class="unlock-block position-relative">
    <!--<a role="button" class="hint-20">?</a>-->
    <div class="mb-2 text-center">前往下一個地點</div>
    <div class="unblock-inside d-flex position-relative">
      <div v-if="showLeftBlock" class="divide-line"></div>
      <a
        v-if="showLeftBlock"
        @click.stop.prevent="gotoMap"
        class="d-flex align-items-center justify-content-center"
        style="flex:1"
      >
        <div>
          <img class="" src="/img/location-see.svg" alt="" />
          <div class="mt-2 icon-intro">區域已解鎖<br />點擊查看</div>
        </div>
      </a>
      <a @click.stop.prevent="checkPosition" class="d-flex align-items-center justify-content-center" style="flex:1">
        <div>
          <img class="" src="/img/location-unlock.svg" alt="" />
          <div class="mt-2 icon-intro">抵達地點後<br />點擊繼續</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { getDistance } from "@/Mixins/getDistance";
import StageFromMixin from "../../Mixins/StageFromMixin";
import UserFromVuex from "../../Mixins/UserFromVuex";
import { getCurrentPosition } from "../../libs/getCurrentPosition";
import ModalMixin from "@/Mixins/ModalMixin";

export default {
  name: "checkPosition",
  mixins: [StageFromMixin, UserFromVuex, ModalMixin],
  data() {
    return {
      userCenter: []
    };
  },
  props: {
    showLeftBlock: {
      //是否顯示左側[顯示地圖]按鈕
      default: true
    },
    location: null,
    local: null,
    zoom: {
      default: 18
    },
    stage_id: null
  },
  methods: {
    gotoMap() {
      this.$router.replace({
        name: "homeMap",
        params: {
          local: this.local,
          zoom: this.zoom
        }
      });
    },
    checkPosition() {
      if (this.stage_id === 6) {
        this.$emit("checkPositionEvent");
        return;
      }
      if (navigator.geolocation) {
        getCurrentPosition(
          position => {
            let p = position.coords;
            let distance = getDistance(
              position.coords.latitude,
              position.coords.longitude,
              this.location.position[0],
              this.location.position[1],
              "M"
            );
            if (distance < this.location.distance) {
              this.setFlag({ key: this.location.nextPhase, status: true });
            } else {
              this.showModal("你還沒到喔");
            }
          },
          err => {
            alert("無法取得定位");
          }
        );
      } else {
        alert("您的瀏覽器不支援地理定位");
      }
      // //

      //this.$emit('showIgStory', this.location.nextPhase)
    }
  }
};
</script>

<style scoped>
.link-blue {
  color: #007bff;
}
</style>
