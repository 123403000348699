<template>
  <div>
    <div class="logout-btn" :class="position" @click="openModalDiv">
      <svg class="logout-svg " viewBox="0 0 24 24">
        <use fill="#fff" xlink:href="#icon-logout" x="0" y="0"></use>
      </svg>
    </div>
    <div class="app-info-modal modal-x" v-if="openModal">
      <p>當出現資料不同步的狀態可以登出後重新登入，資料會重新同步。</p>
      <p class="mb-3">遊戲正常運行時請勿重複登出/登入。</p>
      <div class="pt-3 d-flex justify-content-center">
        <button class="mr-2 btn btn-outline-primary btn-padding" @click="closeModal">取消</button>
        <button class="btn btn-primary text-white btn-padding" @click="logoutHandler">登出</button>
      </div>
    </div>
  </div>
</template>
<script>
import UserFromVuex from "@/Mixins/UserFromVuex";

export default {
  name: "logoutBtn",
  components: {},
  mixins: [UserFromVuex],
  computed: {},
  props: {
    position: {
      type: String,
      required: true,
      default: "upper-right"
    }
  },
  data() {
    return {
      openModal: false
    };
  },
  activated() {},
  mounted() {},
  created() {},
  beforeDestroy() {},
  methods: {
    openModalDiv() {
      this.openModal = true;
    },
    logoutHandler() {
      this.logout();
      this.openModal = false;
    },
    closeModal() {
      this.openModal = false;
    }
  }
};
</script>
<style scoped lang="scss">
.logout-btn {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #1ba7e9;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #1683b5;
  }
  &.upper-right {
    right: 20px;
    top: 10px;
  }
  &.lower-right {
    right: 20px;
    bottom: 10px;
  }
  &.upper-left {
    left: 10px;
    top: 10px;
  }
  .logout-svg {
    width: 30px;
    height: 30px;
  }
}
.modal-x {
  top: 50%;
  transform: translate(-50%, -50%);
  .btn-padding {
    padding-left: 36px;
    padding-right: 36px;
  }
}
</style>
