<template>
  <div class="fighting-bar d-flex">
    <div class="power-bar-bg d-flex" v-bind:class="direction">
      <div class="power-bar" v-bind:style="{ width: percent + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "powerBar",
  props: {
    direction: {
      default: "",
      type: String
    },
    percent: {
      default: 0,
      type: Number
    }
  }
};
</script>

<style scoped>
.power-bar-bg {
  width: 100%;
  background: #2e2e2e;
}
</style>
