export default {
  methods: {
    hideFindPlaceShow() {
      setTimeout(() => {
        this.findPlaceShow = false;
        this.canClick = true;
      }, 0);
    }
  }
};
