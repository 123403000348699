<template>
  <div>
    <div class="vimeoFrame">
      <vimeo vimeo_key="gameIntro" dataWidth="275" dataHeight="275"></vimeo>
    </div>
    <div class="mt-4">
      <p><b>1. 關掉WIFI</b></p>
      <p><b>2. 開啟聲音</b></p>
      <p><b>3. 延長螢幕鎖定時間</b></p>
      <p><b>4. 禁止中離</b></p>
    </div>
  </div>
</template>

<script>
import vimeo from "./vimeo.vue";
export default {
  name: "fightingRuleVideo",
  components: {
    vimeo
  }
};
</script>

<style scoped lang="scss">
.vimeoFrame {
  width: 275px;
  height: 275px;
}
</style>
