<template>
  <div
    class="rule-panel position-absolute"
    v-touch:tap.self="
      () => {
        slideTo(index + 1);
      }
    "
    v-touch:swipe.right.self="
      () => {
        slideTo(index - 1);
      }
    "
    v-touch:swipe.left.self="
      () => {
        slideTo(index + 1);
      }
    "
  >
    <!--<h4 class="mb-4 text-white"></h4>-->
    <div
      class="slide-wrap overflow-hidden position-relative"
      ref="infoBox"
      v-touch:tap="
        () => {
          slideTo(index + 1);
        }
      "
    >
      <ul class="list-unstyled rule-slides d-flex position-absolute" v-bind:style="{ left: slideToOffset }">
        <li v-for="(rule, key) in rules" :key="key">
          <template v-if="rule.component">
            <div :is="rule.component"></div>
          </template>
          <template v-else>
            <div class="" v-html="rule.title"></div>
            <div class="" v-html="rule.content"></div>
          </template>
        </li>
      </ul>
    </div>
    <div class="slide-points d-flex justify-content-between">
      <a @click.stop.prevent="slideTo(index - 1)" v-bind:class="{ disabled: index == 0 }">
        <img src="@/assets/images/icon-backward.svg" alt="" class="white-circle" />
      </a>
      <ul class="list-unstyled d-flex align-items-center m-0">
        <li
          v-for="(rule, key) in rules"
          :key="key"
          v-on:click="slideTo(key)"
          v-bind:class="{ active: key == index }"
        ></li>
      </ul>
      <a @click.stop.prevent="slideTo(index + 1)" v-bind:class="{ disabled: index == rules.length - 1 }">
        <img src="@/assets/images/icon-forward.svg" alt="" class="white-circle" />
      </a>
    </div>
  </div>
</template>

<script>
import fightingRuleEnd from "./fightingRuleEnd.vue";
import danceRuleEnd from "./danceRuleEnd.vue";
import danceRuleVideo from "./danceRuleVideo.vue";
import datingRuleVideo from "./datingRuleVideo.vue";
import fightingRuleVideo from "./fightingRuleVideo";
import ghostRuleVideo from "./ghostRuleVideo.vue";
import datingRuleEnd from "./datingRuleEnd.vue";
import ghostRuleEnd from "./ghostRuleEnd.vue";
import gameIntroVideo from "./gameIntroVideo.vue";

export default {
  name: "rule",
  components: {
    fightingRuleEnd,
    danceRuleEnd,
    datingRuleEnd,
    ghostRuleEnd,
    danceRuleVideo,
    datingRuleVideo,
    fightingRuleVideo,
    ghostRuleVideo,
    gameIntroVideo
  },
  props: {
    rules: {
      default: []
    },
    page: {
      default: null
    }
  },
  data() {
    return {
      index: 0,
      slideToOffset: 0,
      endPage: false
    };
  },
  mounted() {
    if (this.page) {
      this.slideTo(parseInt(this.page) - 1);
    }
  },
  methods: {
    slideTo: function(index) {
      if (index < 0) return;
      if (index >= this.rules.length) return;
      if (index === this.rules.length - 1) {
        this.endPage = true;
        this.$emit("endPage", true);
      } else {
        if (this.endPage) {
          this.endPage = false;
          this.$emit("endPage", false);
        }
      }
      this.$emit("changePage", index);
      this.index = index;
      if (!this.$refs.infoBox) return "0";
      let slideWidth = this.$refs.infoBox.clientWidth;
      let slideMove = 0 - this.index * slideWidth;
      this.slideToOffset = slideMove + "px";
    }
  }
};
</script>

<style lang="scss">
.slide-wrap {
  width: 100vw;
  height: 100%;
}

.rule-panel {
  top: 100px;
  bottom: 100px;

  h3 {
    font-size: 20px;
    padding: 30px 0px 0;
    color: #dbf2ff;
    font-weight: bold;
  }

  .rule-slides {
    transition: 0.5s;
    margin: 0 0 20px;
    height: 100%;

    li {
      flex-shrink: 0;
      width: 100vw;
      height: 100%;
      padding: 0 50px;
      overflow: auto;
    }
  }
}

.slide-points {
  padding: 0 30px;

  a {
    &.disabled {
      opacity: 0;
    }
  }

  li {
    margin: 0 3px;
    background: #d5d5d5;
    width: 6px;
    height: 6px;
    border-radius: 50%;

    &.active {
      background: #000;
    }
  }
}

.white-circle {
  border-radius: 50%;
  padding: 8px;
  background: white;
}
</style>
