<template>
  <div>
    <img class="img-fluid" :src="'/img/' + getImg" alt="" />
    <stageMessageNoInput :data="msg"></stageMessageNoInput>
  </div>
</template>

<script>
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
const winner_map = {
  胖子: "cks-win2.jpg",
  花輪: "cks-win3.jpg",
  小希: "cks-win4.jpg",
  赤木: "cks-win5.jpg",
  白爛: "cks-win6.jpg",
  春哥: "cks-win7.jpg"
};
export default {
  name: "winnerCharacter",
  components: {
    stageMessageNoInput
  },
  computed: {
    getImg: function() {
      return winner_map?.[this.name] || null;
    },
    msg: function() {
      return [
        {
          name: this.name,
          content: this.winnerTalk
        }
      ];
    }
  },
  props: {
    winnerCharacter: {
      default: ""
    },
    winnerTalk: {
      default: ""
    }
  },
  mounted() {
    this.name = this.winnerCharacter?.name ?? this.winnerCharacter;
  },
  data() {
    return {
      name: ""
    };
  }
};
</script>

<style scoped></style>
