<template>
  <div class="mask mask-dark d-flex justify-content-center align-items-center" @click="next">
    <div class="position-relative">
      <div class="favor-character position-absolute" v-show="winner && !stageFinished">
        <div class="position-absolute">{{ name }} - {{ nickname }}</div>
        {{ name }} - {{ nickname }}
      </div>
      <img :src="'/img/' + momo" alt="" />

      <img
        v-if="stageFinished"
        class="position-absolute favorite-text"
        src="/img/favorite-mom-finished-text.png"
        alt=""
      />
      <img v-else class="position-absolute favorite-text" src="/img/favorite-mom-text.png" alt="" />
    </div>
  </div>
</template>

<script>
import FavorFromMixin from "../../Mixins/FavorFromMixin";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "favorMomo",
  mixins: [FavorFromMixin, HowlAudio],
  props: ["defaultName", "defaultNickname", "stageFinished"],
  data() {
    return {
      pictures: ["favorite-momo1.png", "favorite-momo2.png", "favorite-momo3.png"]
    };
  },
  computed: {
    momo: function() {
      let pictureCount = this.pictures.length;
      let picIndex = Math.floor(Math.random() * pictureCount);
      return this.stageFinished ? "favorite-momo3.png" : this.pictures[picIndex];
    },
    name() {
      return this.defaultName || this?.winner?.name || this?.winner;
    },
    nickname() {
      return this.defaultNickname || this?.winner_user?.nickname;
    }
  },
  mounted() {
    var audio = this.makeAudio("/audio/favor-momo.mp3");
    audio.play();
  },
  methods: {
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style scoped lang="scss">
.favor-character {
  bottom: 72px;
  left: 10px;
  transform: rotate(-10deg);
  transform-origin: left top;
  color: #000;
  font-weight: bold;
  font-size: 20px;
  text-shadow: 0 0 4px #fff;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: white;
  div {
    left: 0;
    top: 0;
    color: #000;
    -webkit-text-fill-color: black; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
  }
}
.favorite-text {
  left: 0;
  bottom: 30px;
  max-width: 100%;
}
</style>
