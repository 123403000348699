<template>
  <div
    class="position-relative card-slides"
    ref="slide"
    :style="{ height: height + 'px' }"
    v-touch:tap="
      () => {
        slideTo(index + 1);
      }
    "
    v-touch:swipe.right="
      () => {
        slideTo(index - 1);
      }
    "
    v-touch:swipe.left="
      () => {
        slideTo(index + 1);
      }
    "
  >
    <div class="position-absolute slide-info text-white">{{ index + 1 }}/{{ slides.imgs.length }}</div>
    <ul
      class="list-unstyled position-absolute hint-slides d-flex"
      :style="{ width: slideWidth + 'px', left: slideToOffset }"
    >
      <li class="" v-for="(slide, key) in slides.imgs" :key="key">
        <img ref="slide" :src="slide" alt="" v-if="slide.includes('https')" />
        <img ref="slide" :src="'/img/' + slide" alt="" v-else />
      </li>
    </ul>
    <ul class="list-unstyled d-flex justify-content-center m-0 slide-points">
      <li
        v-for="(slide, key) in slides.imgs"
        :key="key"
        v-on:click="slideTo(key)"
        v-bind:class="{ active: key == index }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "slider",
  props: {
    slides: {
      default: {}
    }
  },
  data() {
    return {
      index: 0,
      width: 0,
      height: 0,
      slideToOffset: 0
    };
  },
  computed: {
    slideWidth() {
      return this.width * this.slides.imgs.length;
    }
  },
  mounted() {
    this.width = this.$refs.slide.clientWidth;
    // console.log(this.width)
    // console.log(this.slides.width)
    let height = (this.width / this.slides.width) * this.slides.height;
    this.height = height;
  },
  methods: {
    slideTo: function(index) {
      if (index < 0) return;
      if (index >= this.slides.imgs.length) return;
      this.index = index;
      // if (!this.$refs.infoBox) return "0";
      let slideWidth = this.width;
      let slideMove = 0 - this.index * slideWidth;
      this.slideToOffset = slideMove + "px";
    }
  }
};
</script>

<style scoped lang="scss">
.card-slides {
  overflow: hidden;
}

.hint-slides {
  transition: left 0.5s;
  li {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.slide-info {
  background: #1c334f;
  border-radius: 20px;
  padding: 2px 20px;
  font-size: 14px;
  right: 20px;
  top: 20px;
  z-index: 10;
}
.slide-points {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  a {
    &.disabled {
      opacity: 0;
    }
  }
  li {
    margin: 0 3px;
    background: #d5d5d5;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    &.active {
      background: #000;
    }
  }
}
</style>
