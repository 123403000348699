<template>
  <div>
    <h3 class="">教學影片</h3>
    <p class="text-white">
      還是不明白嗎？那來看一下示範影片吧！<br />Step1. 選擇角色<br />Step2. 跳起來<br />Step3. 再來一遍
    </p>
    <div>
      <vimeo vimeo_key="danceDemo" dataWidth="260"></vimeo>
    </div>
  </div>
</template>

<script>
import vimeo from "./vimeo.vue";
export default {
  name: "danceRuleVideo",
  components: {
    vimeo
  }
};
</script>

<style scoped>
iframe {
  margin: auto auto;
}
</style>
