<template>
  <div>
    <h3 class="">教學影片</h3>
    <p class="text-white">
      還是不明白嗎？那來看一下示範影片吧！<br />Step1. 躲藏時間<br />Step2. 抓人時間<br />Step3. 獲勝判定
    </p>
    <div>
      <vimeo vimeo_key="ghostDemo" dataWidth="260"></vimeo>
    </div>
  </div>
</template>

<script>
import vimeo from "./vimeo.vue";
export default {
  name: "ghostRuleVideo",
  components: {
    vimeo
  }
};
</script>

<style scoped></style>
