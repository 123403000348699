<template>
  <div class="position-relative full-page fighting-bg">
    <fullpageInfo></fullpageInfo>
    <div class="panel-back"></div>
    <rule :rules="rules"></rule>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import rule from "@/views/part/rule";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import UserFromVuex from "../Mixins/UserFromVuex";

export default {
  name: "fightingRule",
  mixins: [WebSocketMixin, UserFromVuex],
  data() {
    return {
      btnLink: [
        {
          name: "打架 1P",
          link: "",
          users: [
            {
              name: "小希",
              picture: ""
            },
            {
              name: "赤木",
              picture: ""
            }
          ]
        },
        {
          name: "打架 2P",
          link: "",
          users: [
            {
              name: "小希",
              picture: ""
            },
            {
              name: "赤木",
              picture: ""
            }
          ]
        },
        {
          name: "加油打氣",
          link: "",
          users: []
        }
      ],
      rules: [
        {
          title: "<h1 class='text-white text-center'>街頭格鬥戰！</h1>",
          content:
            "<p>本來只是想勸架，不知不覺卻變成所有人打成一團！要打，不用去練舞室，現場直接就開打！打架形式會採用一對一對戰方式進行，可選擇<strong>上台對戰</strong>或是當<strong>鄉民幫忙加油</strong></p>"
        },
        {
          title: "<h3>Step1. 角色選定</h3>",
          content:
            "<p>選擇當『打者』或是當『鄉民』；兩位『打者』須面對面站立、並且將手機緊握在<strong>右手</strong>，『鄉民』則可自行選擇最方便觀賞兩位『打者』的位置看熱鬧、幫忙加油</p><div class='text-center'><img src='img/fightingRule1.png' class='img-fluid'></div>"
        },
        {
          title: "<h3>Step2. 格鬥操作</h3>",
          content:
            "<p class='mb-2'>回合倒數計時後開始，兩位『打者』出招將對方血量條歸零即可獲得該回合勝利！<br/><br/>【攻擊】握緊手機、向前出拳即可發動攻擊</p><div class='text-center'><img src='img/fightingRule2.png' class='img-fluid'></div>"
        },
        {
          title: "<h3></h3>",
          content:
            "<p class='mb-2'>【防禦】握緊手機向上揮，即可防禦對手的攻擊或是大絕招<br/><br/><br/><br/></p><div class='text-center'><img src='img/fightingRule3.png' class='img-fluid'></div>"
        },
        {
          title: "<h3></h3>",
          content:
            "<p class='mb-2'>【大絕招】集氣條集滿後，握緊手機、手劃大圈即可發動大絕招！<br/><br/><br/><br/></p><div class='text-center'><img src='img/fightingRule4.png' class='img-fluid'></div>"
        },
        {
          title: "<h3></h3>",
          content:
            "<p class='mb-2'>【幫集氣】『鄉民』可以點擊集氣鈕幫兩位『打者』集氣，可以讓支持的『打者』更快放大絕，更有機會獲得勝利！<br/><br/><br/></p><div class='text-center'><img src='img/fightingRule5.png' class='img-fluid'></div>"
        },
        {
          title: "<h3></h3>",
          content:
            "<p>『鄉民』幫其中一名打者集氣較多次時的視為同隊，當該『打者』獲得勝利，支持該名『打者』的『鄉民』也會一同獲勝！舉例：小希幫春哥加油5次，幫胖子加油1次，若春哥獲勝則小希也會一同獲勝，若胖子獲勝就算失敗囉！</p>" +
            "<div class='text-center'><img src='img/fightingRule6.png' class='img-fluid'></div>"
        },
        {
          title: "<h3>Step3. 勝利判定</h3>",
          content:
            "<p>率先將對方血量條歸零，或是回合時間結束時血量較多的一方勝利，幫該方加油集氣的鄉民也會一同獲得勝利，得到一點積分<br/><br/><br/></p><div class='text-center'><img src='img/fightingRule7.png' class='img-fluid'></div>"
        },
        {
          component: "fightingRuleVideo"
        },
        {
          title: "<h3>Step6</h3>",
          component: "fightingRuleEnd",
          content:
            "<p class='text-center mb-2'>Ready.. FIGHT!!</p><a class='btn btn-primary text-white btn-block'>前往選擇畫面</a>"
        }
      ]
    };
  },
  components: {
    fullpageInfo,
    rule
  }
};
</script>

<style lang="scss">
.fighting-bg {
  h1 {
    padding-top: 30px;
    font-size: 28px;
    padding-bottom: 30px;
  }

  p {
    color: #fff;
    font-size: 15px;
  }
  strong {
    color: #4285f4;
  }
  img {
    max-width: 220px;
  }
  span {
    color: #c64c72;
  }
}
</style>
