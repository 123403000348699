<template>
  <div class="full-page overflow-auto" ref="container">
    <FavorContainer
      v-if="flag('stage_4_clear') && flag('stage_4_step_4_favor') == false"
      @close="phase4bonusDone"
      :stage="stage"
      :step="1"
      :maximum_step="1"
      :stageFinished="true"
    ></FavorContainer>
    <div class="position-relative stage-content" ref="stage">
      <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
      <moreContent @click="showProgressUpdate = false" v-if="showProgressUpdate"></moreContent>
      <turnPage></turnPage>
      <stageMessage
        hint_key="stage_4_phase1_hint"
        :user="user"
        :stageCharacter="siteCharacter.name"
        :data="phase1Messages"
        :stage="stage"
        :step="1"
        v-on:stageClear="phase1MsgClear"
        v-on:hintAdd="parse1Hint"
      ></stageMessage>
      <FavorContainer
        v-if="flag('stage_4_phase1Clear') && flag('stage_4_phase1Clear_bonus') == false"
        @close="phase1MsgClear_bonus_over"
        :stage="stage"
      ></FavorContainer>

      <checkPosition
        v-show="flag('stage_4_phase1Clear')"
        :local="[25.041679, 121.514963]"
        :zoom="18"
        :location="check1stPosition"
      ></checkPosition>
      <template v-if="flag('stage_4_check1stPlace')">
        <igStoryGoGame :video="phase1IgStory" @goGame="goGame"></igStoryGoGame>
        <stageMessageNoInput :data="phase1IgStoryMsg"></stageMessageNoInput>
      </template>
      <template v-if="flag('stage_4_fightingRule')">
        <div class="d-flex position-relative justify-content-center py-5">
          <a class="d-flex flex-column align-items-center justify-content-center" @click="goFightingRule">
            <div class="text-center">
              <img class="" src="/img/icon-finish.svg" alt="" />
              <div class="mt-2 icon-intro">繼續關卡</div>
            </div>
          </a>
        </div>
      </template>
      <template v-if="flag('stage_4_fightingFinish')">
        <winnerCharacter :winnerCharacter="flag('stage_4_fightingWinner')" :winnerTalk="winnerTalk"></winnerCharacter>
        <img v-if="!flag('stage_4_phase3Clear')" class="img-fluid" :src="analyticsImgPath" alt="" />
        <img v-else src="img/stage4phase3-answer.png" alt="" />
        <FavorContainer
          v-if="flag('stage_4_phase3Messages') && flag('stage_4_phase3Clear_bonus') == false"
          @close="phase3MsgClear_bonus_over"
          :stage="stage"
          :step="2"
        ></FavorContainer>
        <stageMessage
          hint_key="stage_4_phase3_hint"
          :user="user"
          :stageCharacter="siteCharacter.name"
          :data="phase3Messages"
          :stage="stage"
          :step="3"
          v-on:stageClear="phase3MsgClear"
          v-on:hintAdd="parse3Hint"
        ></stageMessage>
      </template>
      <!--      flag:{{ this.flag("stage_4_phase3Clear") }}-->
      <template v-if="flag('stage_4_phase3Clear')">
        <photoMission
          :photo="photoMission"
          :stage="stage"
          @next="next"
          @final="final"
          @showStageFinishedFavor="showStageFinishedFavor"
        >
          <stageMessageNoInput :data="photoMissionMsg"></stageMessageNoInput>
        </photoMission>
      </template>
    </div>
    <mainMenu></mainMenu>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import mainMenu from "@/views/part/mainMenu";
import stageHeader from "@/views/part/stageHeader";
import checkPosition from "@/views/part/checkPosition";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import igStoryGoGame from "@/views/part/igStoryGoGame";
import stageMessage from "@/views/part/stageMessage";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";
import photoMission from "@/views/part/photoMission";
import winnerCharacter from "@/views/part/winnerCharacter";
import moreContent from "@/views/part/moreContent";

import turnPage from "@/views/part/turnPage";

import { mapGetters, mapActions } from "vuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import FavorContainer from "./favor/FavorContainer";
import ShowUpdate from "@/Mixins/ShowUpdateMixin";
import FavorFromMixin from "@/Mixins/FavorFromMixin";

export default {
  name: "park228",
  mixins: [
    Avatar,
    CharacterFromVuex,
    UserFromVuex,
    WebSocketMixin,
    TeamFromVuex,
    StageFromMixin,
    ShowUpdate,
    FavorFromMixin
  ],
  components: {
    mainMenu,
    stageHeader,
    checkPosition,
    igStoryGoGame,
    stageMessage,
    stageMessageNoInput,
    turnPage,
    photoMission,
    FavorContainer,
    winnerCharacter,
    moreContent
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    analyticsImgPath() {
      let check = this.members.findIndex(d => d.user_id == this.user.id) % 2;
      if (check) {
        return "/img/228-analytics1.jpg";
      }
      return "/img/228-analytics2.jpg";
    },
    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    },
    stage_4_fightingRule() {
      return this.flag("stage_4_fightingRule");
    }
  },
  data() {
    return {
      showProgressUpdate: false,
      showProgressUpdateFlags: [
        "stage_4_phase1Clear",
        "stage_4_check1stPlace",
        "stage_4_fightingRule",
        "stage_4_phase3Clear"
      ],
      stage: {
        stage_id: 4
      },
      siteCharacter: {
        name: "胖子"
      },
      stageLocation: {
        time: "高三下",
        location: "228公園"
      },
      phase1Messages: {
        unlockFlag: "stage_4_phase1Clear",
        msgLikes: "胖子和其他20個人都說讚",
        character: "胖子",
        defaultMsg: [
          {
            name: "胖子",
            content:
              "每次補習班結束後去搭捷運，都會經過228公園，也都會很羨慕公園裡談情說愛的高中生情侶。我多希望愛情能像唸書一樣公平，付出多少就能收獲多少"
          },
          {
            name: "胖子",
            content: "在筆記的中心呼喊愛情吧！咦，還真有東西在中心回應我？"
          }
        ],
        answer: "Nice2haveU",
        hintIndex: 0,
        hint: [
          "（1/4）點擊書本可以翻頁，其中一頁有點特別，抓準時機點到那一頁看看<br>（使用提示會減少得到的好感值）",
          "（2/4）一次輸入藍色紙條上所有問題的答案，不要留空白<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕" },
          "（3/4）1.人體組成最多元素依序為碳氫氧氮<br>2.冰塊會漂浮在水面上<br>3.現在式表事實<br>4.相似於拋物線<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "Nice2haveU"
        ]
      },
      check1stPlace: false,
      check1stPosition: {
        nextPhase: "stage_4_check1stPlace",
        position: [25.041679, 121.514963],
        distance: 70
      },
      phase1IgStory: {
        src: "S06",
        poster: "mc/11.jpg",
        autoplay: false,
        stageFlag: "stage_4_goFighting"
      },
      phase1IgStoryMsg: [
        {
          name: "胖子",
          content:
            "高中三年，我才是最真心喜歡小桃的那個人。為了能和小桃一起討論功課，上同一個科系，誰還能比我更拼？因為有了小桃，我才有努力的目標。我立志要考上台大，成為一個能配得上她的人！"
        },
        {
          name: "胖子",
          content: "小桃，考得怎麼樣？"
        },
        {
          name: "小桃",
          content: "數學還可以，但地理考得不怎麼樣。"
        },
        {
          name: "胖子",
          content: "沒關係，我們討論過志願啦，很多科系不採計地理啊～"
        },
        {
          name: "小彥",
          content: "我徹底考砸了，我想我可能要重考，你們好好享受大學生活，不要管我"
        },
        {
          name: "胖子",
          content: "你先別想太多啦，剛考完可以先休息一下啊！說不定你考得很好啊"
        },
        {
          name: "小彥",
          content:
            "我自己考怎麼樣我自己最清楚！我明天就去報重考班了啦！反正你也暗戀小桃三年，趕快把握機會告白啊！趁小桃還沒被學長、大學同學追走前啊！"
        },
        {
          name: "胖子",
          content: "你在講什麼東西！你再講一次！"
        }
      ],
      phase3Messages: {
        unlockFlag: "stage_4_phase3Clear",
        msgLikes: "胖子和其他20個人都說讚",
        character: "胖子",
        defaultMsg: [
          {
            name: "小彥",
            content: "這個是⋯你的落點分析圖，胖子考得不錯嘛！"
          },
          {
            name: "胖子",
            content: "哼！我看你的落點也沒你說得那麼差啊"
          },
          {
            name: "小彥",
            content: "好啦！我們可以<strong>一起</strong>研究怎麼填志願，這樣以後大學還是可以常見面啊"
          },
          {
            name: "胖子",
            content: "那你以後講話別再這麼欠揍…欸，小桃，你拿螢光筆在我們的落點分析圖上畫什麼呀？"
          },
          {
            name: "小桃",
            content: "哼，畫上我要跟你們說的話"
          }
        ],
        answer: ["愛藥奈欣登代子系巡早感決恆眾藥", "愛要耐心等待仔細尋找感覺很重要"],
        hintIndex: 0,
        hint: [
          "（1/4）掉落的落點分析圖好像有兩種<br>（使用提示會減少得到的好感值）",
          "（2/4）兩種落點分析圖上的線條好像有某種對應關係<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）如果將兩種落點分析圖組合，並且照著上面的路線走的話呢？<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "愛藥奈欣登代子系巡早感決恆眾藥"
        ]
      },
      winnerTalk: "為妳和朋友打一場架，沒有理智的樣子，像極了愛情",
      photoMission: {
        img: "photo-228.jpg",
        finish: "stage_4_photo_clear",
        flag: "stage_4_clear",
        local: [25.042336, 121.51583],
        flyTo: [25.042336, 121.51583]
      },
      photoMissionMsg: [
        {
          name: "胖子",
          content: "為妳讀書，為妳打架，為妳做不可能的事。胖子都是潛力股！我不一樣，我會為妳變成績優股"
        },
        {
          name: "提示",
          content:
            "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
        }
      ]
    };
  },
  mounted() {
    this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    stage_4_fightingRule(val, oldVal) {
      if (val !== oldVal) {
        this.fakeFullScreen({ status: false });
      }
    }
  },
  methods: {
    ...mapActions("Video", ["fakeFullScreen"]),
    handleScroll() {
      if (this.showProgressUpdate) {
        this.showProgressUpdate = false;
      }
    },
    next() {
      this.setFlag({ key: "stage_4_photo_clear", status: true });
    },
    final() {
      this.unlockStage(4);
      // this.setFlag({ key: "stage_4_location", status: [25.043365, 121.516628] });
    },
    parse1Hint() {
      this.phase1Messages.hintIndex++;
    },
    phase1MsgClear: function() {
      if (!this.flag("stage_4_phase1Clear")) {
        this.setWinner({
          winner: this.character.name,
          user: this.user,
          stage: 4,
          step: 1
        });
        this.setFlag({ key: "stage_4_phase1Clear", status: true });

        this.sendStageMessage({
          step: 1,
          stage: this.stage,
          newMsg: {
            character: "胖子",
            content: "小桃稱讚我耶！我人真好！",
            stage: this.stage
          }
        });

        this.addScoreByRate({
          score: 10,
          characterName: this.character?.name,
          isMoveStage: false,
          hint_key: "stage_4_phase1_hint"
        });
      }
    },
    phase1MsgClear_bonus_over: function() {
      this.setFlag({ key: "stage_4_phase1Clear_bonus", status: true });
    },
    parse3Hint() {
      this.phase3Messages.hintIndex++;
    },
    phase3MsgClear: function() {
      // this.setFlag({key: "stage_4_fightingFinish", status: true});
      if (this.flag("stage_4_phase3Clear")) return;
      this.setFlag({ key: "stage_4_phase3Clear", status: true });
      this.setFlag({ key: "stage_4_phase3Messages", status: true });

      this.addScoreByRate({
        score: 10,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_4_phase3_hint"
      });
    },
    phase3MsgClear_bonus_over() {
      this.setFlag({ key: "stage_4_phase3Clear_bonus", status: true });
    },
    goGame: function() {
      // const data = {
      //   method: "EnterStage",
      //   token: this.token,
      //   location: {
      //     link: "fightingRule"
      //   }
      // };
      // sendMessage(data);
      this.setFlag({ key: "stage_4_fightingRule", status: true });
      // this.$router.replace('fightingRule')
    },
    goFightingRule() {
      if (this.flag("fighting_over")) {
        this.$router.replace("fightingResult");
        return;
      }
      this.$router.replace("fightingRule");
    },
    FavorContainer: function() {
      this.setFlag({ key: "stage_4_step_4_favor", status: true });
      this.$router.push("homeMap");
    },
    showStageFinishedFavor() {
      this.setFlag({ key: "stage_4_phase4Clear", status: true });
    },
    phase4bonusDone: function() {
      this.setFlag({ key: "stage_4_step_4_favor", status: true });
      this.$router.push("homeMap");
    }
  }
};
</script>

<style scoped></style>
