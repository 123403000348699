<template>
  <div>
    <h3 class="">積分回合制</h3>
    <p class="mb-2 text-white">
      格鬥戰需進行<strong>至少兩回合</strong>才可進行積分總結算，如果有恩怨解決不了，也能無限制上訴、打到你們決出勝負為止！總結算時，積分最高的人獲勝並且得到小桃的好感值！
    </p>
    <button class="btn btn-block btn-primary btn-sm" @click="start">開始</button>
  </div>
</template>

<script>
import { getDeviceMotionPermission } from "@/utils/Motion";

export default {
  name: "fightingRuleEnd",
  methods: {
    start() {
      getDeviceMotionPermission();
      this.$router.replace("/fightingStandBy");
    }
  }
};
</script>

<style scoped></style>
