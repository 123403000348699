<template>
  <div class="fighting-bar d-flex" v-bind:class="direction">
    <div class="life-bar" v-bind:style="{ width: percent + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: "progressBar",
  props: {
    direction: {
      default: "",
      type: String
    },
    percent: {
      default: 0,
      type: Number
    }
  }
};
</script>

<style scoped></style>
