export default {
  computed:{
    stage_4_fightingFinish(){
      return this.flag('stage_4_fightingFinish')
    }
  },
  data() {
    return {
      fightOverTimeout: null
    };
  },
  methods: {
    fightOver() {
      this.$router.replace("fightingResult");
    }
  },
  beforeDestroy() {
    clearTimeout(this.fightOverTimeout);
  }
};
