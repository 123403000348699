<template>
  <div class="stage-message">
    <ul class="list-unstyled msg-list">
      <li v-for="(message, index) in data" :key="index" class="pb-2 d-flex">
        <b>{{ message.name ? message.name : "謎之音" }}</b>
        <div v-html="message.content"></div>
      </li>
      <ul class="list-unstyled msg-list">
        <li v-for="(message, index) in messages" :key="index" class="pb-2">
          <div v-bind:class="[message.result]" class="d-flex">
            <b>{{ message.name || message.character || defaultWhoTalk }}</b>
            <div v-if="message.is_hint" @click="copy(message.content)" v-html="message.content"></div>
            <div v-else @click="copy(message.content)" v-text="message.content"></div>
          </div>
        </li>
      </ul>
      <li v-for="(message, index) in computedHint_messages" :key="`hint_${index}`" class="pb-2 d-flex">
        <b>{{ message.name ? message.name : "謎之音" }}</b>
        <div v-html="message.content"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import ModalMixin from "../../Mixins/ModalMixin";
import StageMessageMixin from "../../Mixins/StageMessageMixin";

export default {
  name: "stageMessageNoInput",
  mixins: [Avatar, CharacterFromVuex, TeamFromVuex, WebSocketMixin, ModalMixin,StageMessageMixin],
  props: {
    hint_messages: null,
  },
  computed: {
    computedHint_messages() {
      let hint_messages = this.hint_messages;
      if (hint_messages) {
        hint_messages = hint_messages.filter(d => {
          if (d.only) return d.only == this.vuex_user.id;
          return true;
        });
      }
      return hint_messages;
    }
  },
  data() {
    return {
      limit: 20,
      talkContent: "",
      clear: false
    };
  },
  methods: {}
};
</script>

<style scoped></style>
