<template>
  <div class="position-relative">
    <!--<div class="position-absolute play-block" @click.stop="videoControl"></div>-->
    <div class="igstory-wrap" v-bind:class="{ active: videoPaused }">
      <img class="cover-fit" @click.stop="videoControl" v-show="videoPaused" :src="'/img/' + video.poster" alt="" />
      <!--<a class="skip-btn position-absolute text-white" @click="skipVideo" v-show="!videoPaused">Skip</a>-->
      <div
        ref="vimeo"
        :data-vimeo-url="vimeo_url"
        :data-vimeo-width="width"
        :id="element_id"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></div>
    </div>
  </div>
</template>

<script>
import StageFromMixin from "../../Mixins/StageFromMixin";
import { mapGetters, mapActions } from "vuex";
import player from "@vimeo/player";

export default {
  name: "igStory",
  mixins: [StageFromMixin],
  data() {
    return {
      width: 300,
      igVideo: null,
      videoElement: null,
      videoEnd: false,
      videoPaused: true,
    };
  },
  computed: {
    ...mapGetters("Video", ["getVideoPath", "getVimeoId"]),
    element_id() {
      return "vimeo-" + this.video.src;
    },
    vimeo_url() {
      return this.getVimeoId(this.video.src);
    }
  },
  props: {
    video: null,
    autoplay: {
      default: false
    },
    fullscreen: {
      default: true
    },
    overBackOffscreen:{
      default:true
    }
  },
  watch: {},
  methods: {
    ...mapActions("Video", ["fakeFullScreen"]),
    videoControl() {
      this.loadVideo();
      this.igVideo.play();
      this.fakeFullScreen({ status: true, elem: this.$refs.vimeo });
      this.videoPaused = false;
    },
    async skipVideo() {
      if (this.igVideo) {
        let duration = await this.igVideo.getDuration();
        this.igVideo.setCurrentTime(duration);
      }
      this.videoEnd = true;
      this.videoPaused = true;
      this.fakeFullScreen({ status: false, elem: this.$refs.vimeo });

      this.setFlag({ key: this.video.nextPhase, status: true });
    },
    loadVideo() {
      this.igVideo = new player(this.element_id, {
        autoplay: this.autoplay
      });
      this.igVideo.on("play", () => {
        // console.log("play")
        this.videoPaused = false;
      });
      this.igVideo.on("pause", () => {
        // console.log("pause")
        this.videoEnd = true;
        this.videoPaused = true;
        this.fakeFullScreen({ status: false, elem: this.$refs.vimeo });
      });
      this.igVideo.on("ended", () => {
        // document.exitFullscreen();
        this.videoEnd = true;
        this.videoPaused = true;
        this.setFlag({ key: this.video.nextPhase, status: true });
        this.fakeFullScreen({ status: false, elem: this.$refs.vimeo });
      });
    }
  },
  mounted() {
    this.width = window.innerWidth;
    this.$nextTick(() => {
      this.loadVideo();
    });
  },
  beforeDestroy() {
    //北一女不能播完就恢復全螢幕
    if(this.overBackOffscreen){
      this.fakeFullScreen({ status: false, elem: this.$refs.vimeo });
    }
  }
};
</script>

<style scoped lang="scss">
video {
  width: 100%;
  height: auto;
}
</style>
