const defaultOptions = {
  enableHighAccuracy: false,
  timeout: 10000,
  maximumAge: 0,
}
export const getCurrentPosition = function (success, error = null, options = {}) {
  options = {
    ...defaultOptions,
    ...options
  }
  return navigator.geolocation.getCurrentPosition(success, error, options)
}

export const getCurrentPositionPromise = function (options = {}) {
  options = {
    ...defaultOptions,
    ...options
  }
  if (navigator.geolocation) {
    return new Promise(
      (resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject,options)
    )
  } else {
    return new Promise(
      resolve => resolve({})
    )
  }
}
