<template>
  <div class="more-hint position-fixed" @click="goDown">
    <img src="/img/more-content.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "moreContent",
  methods: {
    goDown() {
      this.$parent.$refs.container.scrollTop = this.$parent.$refs.stage.scrollHeight;
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes hintAnimation {
  0% {
    opacity: 0.5;
    transform: translateY(-20px);
  }
  20% {
    transform: translateY(-20px);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
.more-hint {
  bottom: 90px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100;
  img {
    width: 120px;
    animation: hintAnimation 2s 30;
  }
}
</style>
