<template>
  <div>
    <h3 class="">教學影片</h3>
    <p class="text-white">
      還是不明白嗎？那來看一下示範影片吧！<br />Step1. 角色選定<br />Step2. 格鬥操作<br />Step3. 勝利判定
    </p>
    <div>
      <vimeo vimeo_key="fightDemo" dataWidth="260"></vimeo>
    </div>
  </div>
</template>

<script>
import vimeo from "./vimeo.vue";
export default {
  name: "fightingRuleVideo",
  components: {
    vimeo
  }
};
</script>

<style scoped></style>
