var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(
    function () {
      _vm.slideTo(_vm.index + 1);
    }
  ),expression:"\n    () => {\n      slideTo(index + 1);\n    }\n  ",arg:"tap"},{name:"touch",rawName:"v-touch:swipe.right",value:(
    function () {
      _vm.slideTo(_vm.index - 1);
    }
  ),expression:"\n    () => {\n      slideTo(index - 1);\n    }\n  ",arg:"swipe",modifiers:{"right":true}},{name:"touch",rawName:"v-touch:swipe.left",value:(
    function () {
      _vm.slideTo(_vm.index + 1);
    }
  ),expression:"\n    () => {\n      slideTo(index + 1);\n    }\n  ",arg:"swipe",modifiers:{"left":true}}],ref:"slide",staticClass:"position-relative card-slides",style:({ height: _vm.height + 'px' })},[_c('div',{staticClass:"position-absolute slide-info text-white"},[_vm._v(_vm._s(_vm.index + 1)+"/"+_vm._s(_vm.slides.imgs.length))]),_c('ul',{staticClass:"list-unstyled position-absolute hint-slides d-flex",style:({ width: _vm.slideWidth + 'px', left: _vm.slideToOffset })},_vm._l((_vm.slides.imgs),function(slide,key){return _c('li',{key:key},[(slide.includes('https'))?_c('img',{ref:"slide",refInFor:true,attrs:{"src":slide,"alt":""}}):_c('img',{ref:"slide",refInFor:true,attrs:{"src":'/img/' + slide,"alt":""}})])}),0),_c('ul',{staticClass:"list-unstyled d-flex justify-content-center m-0 slide-points"},_vm._l((_vm.slides.imgs),function(slide,key){return _c('li',{key:key,class:{ active: key == _vm.index },on:{"click":function($event){return _vm.slideTo(key)}}})}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }