<template>
  <div class="position-relative" @click="cdClick">
    <findPlace v-if="findPlaceShow"></findPlace>
    <svg class="btn-icon-28 hint-btn" @click.stop="hintBtnClick">
      <use xlink:href="#icon-tip" class="fill-blue active"></use>
    </svg>
    <img class="img-fluid" src="/img/csh-find3.jpg" alt="" />
    <img
      v-if="flag('stage_3_phase2_find3_clear')"
      src="/img/csh-find-3-p.png"
      class="position-absolute road-person"
      alt=""
      @click="showNextMsg"
    />
    <img
      v-if="flag('stage_3_phase2_find3_p1')"
      class="position-absolute ans-circle"
      src="/img/csh-find3-1.png"
      alt=""
    />
    <img
      v-if="flag('stage_3_phase2_find3_p2')"
      class="position-absolute ans-circle"
      src="/img/csh-find3-2.png"
      alt=""
    />
    <img
      v-if="flag('stage_3_phase2_find3_p3')"
      class="position-absolute ans-circle"
      src="/img/csh-find3-3.png"
      alt=""
    />
    <svg
      class="position-absolute ans-circle click-detect"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 750 930"
      style="enable-background:new 0 0 750 930;"
      xml:space="preserve"
    >
      <g @click="clickArea(0)" class="click-area">
        <path d="M172,465c8.9-21-27-44-57-35s-39,36-36,46S141,538,172,465z" />
      </g>
      <g @click="clickArea(1)" class="click-area">
        <path class="click-area" d="M615,456c24.6-6.7,40,14,43,29s-14,36-21,42s-51-8-52-24S593,462,615,456z" />
      </g>
      <g @click="clickArea(2)" class="click-area">
        <path c d="M359,565c45-1.9,239,13,255,41s-56,60-84,68s-153,19-184,16S63,644,62,628S150,574,359,565z" />
      </g>
    </svg>
  </div>
</template>

<script>
import StageFromMixin from "../../Mixins/StageFromMixin";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import findPlace from "@/views/part/findPlace";
import delayEmit from "@/Mixins/delayEmit";
import FindMixin from "@/Mixins/FindMixin";

export default {
  name: "zshFind3",
  components: {
    findPlace
  },
  mixins: [StageFromMixin, TeamFromVuex, delayEmit, FindMixin],
  props: {
    step: {
      default: 0
    }
  },
  data() {
    return {
      findPlaceShow: false,
      canClick: true,
      detectArea0: false,
      detectArea1: false,
      detectArea2: false
      // detectArea: [ false, false, false]
    };
  },
  computed: {
    correctCount: function() {
      let count = 0;
      if (this.flag("stage_3_phase2_find3_p1")) {
        count++;
      }
      if (this.flag("stage_3_phase2_find3_p2")) {
        count++;
      }
      if (this.flag("stage_3_phase2_find3_p3")) {
        count++;
      }
      return count;
    }
  },
  methods: {
    cdClick() {
      if (!this.canClick) return;

      if (!this.flag("stage_3_phase2_find3_clear")) {
        this.findPlaceShow = true;
        this.canClick = false;
        setTimeout(() => {
          this.canClick = true;
          this.findPlaceShow = false;
        }, 5000);
      }
    },
    clickArea(area) {
      if (!this.canClick) return;

      this.findPlaceShow = false;
      this.canClick = true;
      switch (area) {
        case 0:
          if (!this.flag("stage_3_phase2_find3_p1")) {
            this.hideFindPlaceShow();
            this.delayEmit("showfavor");
            this.setFlag({ key: "stage_3_phase2_find3_p1", status: true });
            // this.addScore(2);
          }
          break;
        case 1:
          if (!this.flag("stage_3_phase2_find3_p2")) {
            this.hideFindPlaceShow();
            this.delayEmit("showfavor");

            this.setFlag({ key: "stage_3_phase2_find3_p2", status: true });
            // this.addScore(2);
          }
          break;
        case 2:
          if (!this.flag("stage_3_phase2_find3_p3")) {
            this.hideFindPlaceShow();
            this.delayEmit("showfavor");
            this.setFlag({ key: "stage_3_phase2_find3_p3", status: true });
            // this.addScore(2);
          }
          break;
      }
      if (this.correctCount >= 3) {
        this.setFlag({ key: "stage_3_phase2_find3_clear", status: true });
      }
    },
    showNextMsg() {
      this.setFlag({ key: "stage_3_phase2Clear", status: true });
    },
    hintBtnClick() {
      this.$emit("hintBtnClick", "phase2Find3", this.step);
    }
  }
};
</script>

<style scoped lang="scss">
.hint-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
}
.road-person {
  left: 30vw;
  top: 50vw;
  height: 30vw;
  z-index: 99;
}

.click-area {
  fill: rgba(255, 255, 255, 0);
}

.ans-circle {
  width: 100%;
  left: 0;
  top: 0;
}

.click-detect {
  z-index: 10;
}
</style>
