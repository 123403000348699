<template>
  <div class="position-relative full-page d-flex flex-column fighting-bg">
    <div v-show="ready" class="ready-block">READY</div>
    <div class="opponent-block">
      <a
        v-if="!checkMotionEvent"
        @click="po"
        class="position-fixed page-btn d-flex justify-content-center align-items-center"
      >
        開始遊戲
      </a>
      <a
        v-if="game_over"
        @click="fightOver"
        class="position-fixed page-btn d-flex justify-content-center align-items-center"
      >
        看結果
      </a>
      <div class="position-relative">
        <div class="fighting-info-bar d-flex">
          <div class="w-100">
            <div class="progress-left">
              <div class="d-flex">
                <div class="gamer-bar">1P {{ player_1.name }}</div>
              </div>
              <div class="fighting-bar-wrap position-relative">
                <div class="fight-corner"></div>
                <progressBar :percent="player1_life"></progressBar>
                <powerBar :percent="player1_power"></powerBar>
              </div>
            </div>
          </div>
        </div>
        <div class="fighting-cha-circle circle position-absolute">
          <img class="cover-fit" v-bind:src="showAvatar(player_1.picture)" alt="" />
        </div>
      </div>
    </div>
    <!--atk:{{this.punchShow}}|def:{{this.defenseShow}}-->
    <div class="fighting-scene position-relative overflow-hidden">
      <div class="position-absolute fight-cha user-side">
        <img class="contain-fit" :src="'/img/' + this.player1_animate" alt="" />
      </div>
      <div class="position-absolute fight-cha opponent-side">
        <img class="contain-fit" :src="'/img/' + this.player2_animate" alt="" />
      </div>
    </div>
    <div class="control-block">
      <div class="position-relative h-100">
        <div class="position-absolute fight-countdown text-center">
          <div class="time-title">TIME</div>
          <div class="time-panel">
            {{ time }}
          </div>
        </div>
        <div class="fighting-info-bar d-flex">
          <div class="fighting-cha-circle circle position-absolute">
            <img class="cover-fit" v-bind:src="showAvatar(player_2.picture)" alt="" />
          </div>
          <div class="w-100">
            <div class="progress-right">
              <div class="d-flex justify-content-end">
                <div class="gamer-bar gamer-bar-right">2P {{ player_2.name }}</div>
              </div>
              <div class="fighting-bar-wrap position-relative">
                <div class="fight-corner"></div>
                <progressBar :percent="player2_life" :direction="'justify-content-end'"></progressBar>
                <powerBar :percent="player2_power" :direction="'justify-content-end'"></powerBar>
              </div>
            </div>
          </div>
        </div>
        <div class="fight-act-block">
          <div class="d-flex align-items-center"
               @click="()=>{flag('touchable') && punch()}">
            <div class="action-title">出拳</div>
            <img class="move-gesture" src="img/move-punch.png" alt="" />
          </div>
          <div class="d-flex align-items-center"
               @click="()=>{flag('touchable') && defense()}">
            <div class="action-title">防禦</div>
            <img class="move-gesture" src="img/move-deffense.png" alt="" />
          </div>
          <div class="d-flex align-items-center"
               @click="()=>{flag('touchable') && powerPunch()}">
            <div class="action-title">絕招</div>
            <img class="move-gesture" src="img/move-power.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed scene-text">
      <img v-show="textReady" src="img/fight-text-ready.png" alt="" />
      <img v-show="textFight" src="img/fight-text-fight.png" alt="" />
      <img v-show="textFinal" src="img/fight-text-win.png" alt="" />
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import progressBar from "@/views/part/progressBar";
import powerBar from "@/views/part/powerBar";
import { mapActions, mapGetters } from "vuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";

import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import { setDeviceMotionEvent } from "../utils/Motion";
import StageFromMixin from "../Mixins/StageFromMixin";
import FavorContainer from "./favor/FavorContainer";
import throttle from "lodash.throttle";
import FightMixin from "@/Mixins/FightMixin";
import { isIOS } from "@/utils/OS";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "fighting",
  mixins: [
    Avatar,
    CharacterFromVuex,
    WebSocketMixin,
    UserFromVuex,
    TeamFromVuex,
    StageFromMixin,
    FightMixin,
    HowlAudio
  ],
  components: {
    progressBar,
    powerBar
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Fight", [
      "player1_animate",
      "player2_animate",
      "player1_life",
      "player2_life",
      "player1_power",
      "player2_power",
      "game_times"
    ]),
    player() {
      let player1_character_name = this.team_payload?.fighting?.role?.player1?.character?.name;
      if (this.character.name == player1_character_name) return "player1";
      return "player2";
    },
    player_power() {
      if (this.player) {
        return this[this.player + "_power"] || 0;
      }
      return 0;
    },
    game_over() {
      return this.player1_life <= 0 || this.player2_life <= 0 || this.time == 0;
    }
  },
  watch: {
    stage_4_fightingFinish(val){
      if(val){
        this.$router.replace("park228");
      }
    },
    game_over(val, oldVal) {
      if (val == oldVal) return;
      if (val == true) {
        this.gameOver({
          player1_character: this.player_1.name,
          player2_character: this.player_2.name,
          player1_life: this.player1_life,
          player2_life: this.player2_life
        });
        // this.setFlag({ key: "fighting_over", status: true });
        // this.setFlag({ key: "stage_4_fightingFinish", status: true });
        this.textFinal = true;
        this.audio_winner.play();

        clearInterval(this.timer);
        this.fightOverTimeout = setTimeout(this.fightOver, 3000);
        // this.fightingGoal();
      }
    }
  },
  data() {
    return {
      stage: { stage_id: 7 },
      ready: false,
      time: 60,
      timer: null,
      textReady: true,
      textFight: false,
      textFinal: false,
      powerPunchPower: 0,
      punchShow: 0,
      defenseShow: 0,
      inOtherAct: false,
      showFight_timeout: null,
      beginCountDown_timeout: null,
      fighting_bonus: false,
      checkMotionEvent: false,
      audio_fight1: 0,
      audio_fight2: 0,
      audio_fight3: 0,
      audio_bgm: this.makeAudio("/audio/fighting-bgm.mp3"),
      audio_ready: this.makeAudio("/audio/fighting-ready.mp3"),
      audio_winner: this.makeAudio("/audio/fighting-game-set.mp3"),
      player_1: {
        name: "赤木",
        picture: "avatar5@2x.jpg",
        lock: false,
        life: 100,
        charge: 0
      },
      player_2: {
        name: "花輪",
        picture: "avatar3@2x.jpg",
        lock: false,
        life: 100,
        charge: 0
      },
      action: [
        {
          name: "胖子",
          ready: "fighting2_ready.png",
          punch: "fighting2_punch.png",
          defence: "fighting2_def.png",
          beaten: "fighting2_beaten.png",
          skill: "fighting2_skill.png",
          loose: "fighting2_loose.png",
          win: "fighting2_win.png"
        },
        {
          name: "花輪",
          ready: "fighting3_ready.png",
          punch: "fighting3_punch.png",
          defence: "fighting3_def.png",
          beaten: "fighting3_beaten.png",
          skill: "fighting3_skill.png",
          loose: "fighting3_loose.png",
          win: "fighting3_win.png"
        },
        {
          name: "小希",
          ready: "fighting4_ready.png",
          punch: "fighting4_punch.png",
          defence: "fighting4_def.png",
          beaten: "fighting4_beaten.png",
          skill: "fighting4_skill.png",
          loose: "fighting4_loose.png",
          win: "fighting4_win.png"
        },
        {
          name: "赤木",
          ready: "fighting5_ready.png",
          punch: "fighting5_punch.png",
          defence: "fighting5_def.png",
          beaten: "fighting5_beaten.png",
          skill: "fighting5_skill.png",
          loose: "fighting5_loose.png",
          win: "fighting5_win.png"
        },
        {
          name: "白爛",
          ready: "fighting6_ready.png",
          punch: "fighting6_punch.png",
          defence: "fighting6_def.png",
          beaten: "fighting6_beaten.png",
          skill: "fighting6_skill.png",
          loose: "fighting6_loose.png",
          win: "fighting6_win.png"
        },
        {
          name: "春哥",
          ready: "fighting7_ready.png",
          punch: "fighting7_punch.png",
          defence: "fighting7_def.png",
          beaten: "fighting7_beaten.png",
          skill: "fighting7_skill.png",
          loose: "fighting7_loose.png",
          win: "fighting7_win.png"
        }
      ]
    };
  },
  mounted: function() {
    // this.characterAction("赤木", "ready");
    this.setFlag({ key: "fighting_over", status: false });
    this.setFlag({ key: "fighting_bonus", status: false });
    this.fighting_bonus = false;
    let player1 = this.team_payload.fighting.role.player1;
    let player2 = this.team_payload.fighting.role.player2;
    this.player_1.name = player1.character.name;
    this.player_1.picture = player1.character.picture;
    this.player_2.name = player2.character.name;
    this.player_2.picture = player2.character.picture;

    this.audio_ready.play();
    var audio = this.makeAudio("/audio/fighting-ready.mp3"); // path to file
    audio.play();
    // this.player1_animate = this.characterAction(this.player_1.name, 'ready');
    this.setPlayerAnimate({
      character: this.player_1.name,
      player: "player1",
      move: "ready"
    });
    // this.player2_animate = this.characterAction(this.player_2.name, 'ready');
    this.setPlayerAnimate({
      character: this.player_2.name,
      player: "player2",
      move: "ready"
    });
    this.player_1.lock = false;
    this.player_2.lock = false;
    this.showFight_timeout = setTimeout(this.showFight, 1200);
    // alert("check in mount!!!!!!!!!!!");
    // this.po();
  },
  methods: {
    // ...mapActions('Stage',['fightMove']),
    ...mapActions("Team", ["setPayload"]),
    ...mapActions("Fight", ["setPlayerAnimate", "fightMove", "gameOver", "incrementGameTimes", "fightingGoal"]),
    po() {
      // console.log("!!!!!! check checkMotionEvent bef:",this.checkMotionEvent);
      this.checkMotionEvent = true;
      // console.log("!!!!!! check checkMotionEvent:",this.checkMotionEvent);
      setDeviceMotionEvent(this.checkFight, console.log);

      //利用click事件預載聲音
      this.audio_bgm.play();

      this.audio_fight1 = this.makeAudio("/sound/fight-" + this.character.name + "-1.mp3");
      this.audio_fight2 = this.makeAudio("/sound/fight-" + this.character.name + "-2.mp3");
      this.audio_fight3 = this.makeAudio("/sound/fight-" + this.character.name + "-3.mp3");

      /*
      this.audio_fight1.play().then(_ => {
        this.audio_fight1.pause();
      });
      this.audio_fight2.play().then(_ => {
        this.audio_fight2.pause();
      });
      this.audio_fight3.play().then(_ => {
        this.audio_fight3.pause();
      });*/
    },
    checkFight(event) {
      let x = event?.acceleration?.x;
      let y = event?.acceleration?.y;

      if (isIOS()) {
        x = x * 9.81;
        y = y * 9.81;
      }
      let punch_power = Math.abs(x) * 1.5;
      let def_power = Math.abs(y);

      if (this.inOtherAct) {
        return;
      }

      if (this.game_over) {
        return;
      }
      // else if (punch_power < 20 && def_power < 20)
      // {
      //   return;
      // }

      this.storePowerPunch(punch_power, def_power);
      this.showPower(punch_power, def_power);
      this.doFight(punch_power, def_power);
    },
    storePowerPunch: async function(punch_power, def_power) {
      if (this.player_power < 100) {
        this.powerPunchPower = 0;
        return false;
      }
      if (punch_power < 3.75 && def_power < 10) {
        this.powerPunchPower = 0;
        return false;
      }
      this.powerPunchPower += 1;
      console.log("check powerPunchPower: ", this.powerPunchPower);

      // if(this.powerPunchPower > 5)
      // {
      //   await this.wait(3000, this.powerPunch);
      //   console.log("end powerPunch!!!!!!!!!");
      //   return true;
      // }
    },
    showPower: throttle(function(punch_power, def_power) {
      this.punchShow = punch_power;
      this.defenseShow = def_power;
    }, 500),
    doFight: throttle(async function(punch_power, def_power) {
      if (punch_power < 3.75 && def_power < 10 && this.powerPunchPower <= 0) {
        return;
      }
      console.log("!!!doFight ", punch_power, "|", def_power);
      console.log("if: ", punch_power > def_power, "|else|", punch_power < def_power);
      console.log("check powerPunchPower: ", this.powerPunchPower);

      if (this.powerPunchPower > 5) {
        console.log("ready for Powerpunch!!!!!");
        this.inOtherAct = true;
        // audio = new Audio("/audio/msg-sound.mp3");
        await this.wait(3000, this.powerPunch);
        this.inOtherAct = false;
        console.log("ready for Powerpunch end!!!!!");
      } else if (punch_power >= def_power) {
        console.log("ready for punch!!!!!");
        this.inOtherAct = true;
        await this.wait(1000, this.punch);
        console.log("ready for punch end!!!!!");
        this.inOtherAct = false;
        // return;
      } else if (punch_power < def_power) {
        this.inOtherAct = true;
        console.log("ready for def!!!!!");
        await this.wait(1000, this.defense);
        // audio.oncanplay = function(){ this.play() };
        console.log("ready for def end!!!!!");
        this.inOtherAct = false;
        // return;
      }
    }, 500),

    reset: function(action, character, player) {
      let delay = action == "skill" ? 3000 : 1000;
      if (action !== "ready") {
        setTimeout(() => {
          this[player + "_animate"] = this.characterAction(character.name, "ready");
          if (this.player_1.name == character.name) {
            this.player_1.lock = false;
          } else {
            this.player_2.lock = false;
          }
        }, delay);
      }
    },

    doAction: function(action, character) {
      let self = this;
      // throttle(function (){
      if (self.game_over) {
        return;
      }

      character = character || self.character.name;

      if (action != "ready") {
        self.fightMove({
          character,
          move: action,
          player: self.player
        });
      }
      // }, waitTime)();
    },

    showAction(action, character) {
      character = character || this.character.name;
      if (this.player_1.name == character) {
        if (this.player_1.lock) return;
      } else {
        if (this.player_2.lock) return;
      }
      this.characterAction(character, action);
      this.reset(action, character);
    },
    punch(character) {
      console.log("in punch!!!!!");
      this.audio_fight1.pause();
      this.audio_fight1.play();
      this.doAction("punch", character);
    },
    defense(character) {
      console.log("in defense!!!!!");
      this.audio_fight2.pause();
      this.audio_fight2.play();
      this.doAction("defence", character);
    },
    powerPunch(character) {
      if (this.player_power < 100) return;
      this.audio_fight3.pause();
      this.audio_fight3.play();
      this.doAction("skill", character);
    },
    wait: async function(ms, fn, ...args) {
      fn(...args);
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    characterAction: function(characterName, action) {
      characterName = characterName || this.character.name;
      let characterSelect = this.action.find(people => {
        return people.name == characterName;
      });
      let characterAction = characterSelect[action];
      if (this.player_1.name == characterName) {
        this.player_1.lock = true;
        // this.player1_animate = characterAction;
      } else {
        this.player_2.lock = true;
        // this.player2_animate = characterAction;
      }
      return characterAction;
    },
    showFight() {
      this.textFight = true;
      this.textReady = false;
      this.beginCountDown_timeout = setTimeout(this.beginCountDown, 1200);
      clearTimeout(this.showFight_timeout);
      // this.timer()
    },
    beginCountDown() {
      this.textFight = false;
      clearTimeout(this.beginCountDown_timeout);
      this.fightTimer();
    },
    fightTimer() {
      // let my = this
      this.timer = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.textFinal = true;
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    this.audio_bgm.pause();

    clearInterval(this.timer);
    window.removeEventListener("devicemotion", this.checkFight);
    // clearTimeout(this.fightBonusOverTimeout);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.fighting-bg {
  background: url("/img/fightSecne-Bg.jpg") center center;
  background-size: cover;
  color: #1c334f;
}

.fighting-bg img {
  max-width: none;
}

.gamer-bar {
  margin-top: 2px;
  background: $fighting-dark;
  color: #fff;
  font-size: 12px;
  border-radius: 0 20px 20px 0;
  padding: 0px 30px;
  margin-left: -20px;
  border: 2px solid #fff;

  &.gamer-bar-right {
    margin-left: 0px;
    margin-right: -20px;
    border-radius: 20px 0 0 20px;
  }
}

.fighting-cha-circle {
  height: 75px;
  width: 75px;
  border: 5px solid $fighting-dark;
  z-index: 10;
}

.fighting-bar-wrap {
  border: 5px solid $fighting-dark;

  .fight-corner {
    position: absolute;
    background: $fighting-dark;
    top: 0;
    bottom: 0;
    width: 10px;
  }
}

.progress-left {
  margin-left: 60px;

  .fighting-bar-wrap {
    clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0 100%);

    .fight-corner {
      right: 0;
      clip-path: polygon(6px 0, 100% 0%, 100% 100%, 0 100%);
    }
  }
}

.progress-right {
  margin-right: 60px;

  .power-bar-bg {
    justify-content: flex-end;
  }

  .fighting-bar-wrap {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10px 100%);

    .fight-corner {
      left: 0;
      clip-path: polygon(0 0, 4px 0, 100% 100%, 0% 100%);
    }
  }
}

.fighting-info-bar {
  /*height: 65px;*/
  width: 100%;
}

.opponent-block {
  padding: 50px 30px 30px;

  .fighting-cha-circle {
    left: 0;
    top: 0;
  }
}

.fighting-scene {
  height: 56.22vw;
  background: url(/img/fighting_bg.jpg) center center;
  background-size: cover;
}

.control-block {
  padding: 30px;
  flex-grow: 1;

  .fighting-cha-circle {
    right: 0;
    top: 0;
  }
}

.move-gesture {
  height: 41px;
}

.fight-act-block {
  margin-top: 20px;

  & > div {
    margin-bottom: 20px;
  }

  .action-title {
    font-size: 18px;
    background: #fff;
    margin-left: -30px;
    margin-right: 16px;
    border-radius: 0 30px 30px 0;
    padding: 7px 14px 7px 40px;
    @include default-shadow;
  }
}

.fight-countdown {
  right: 0;
  bottom: -30px;
  width: 91px;
  background: #fff;
  padding: 22px 0 30px;
  border-radius: 50px 50px 0 0;

  .time-title {
    font-weight: bold;
    bargin-bottom: 10px;
  }

  .time-panel {
    padding: 10px;
    font-size: 32px;
    color: #1c334f;
    font-weight: bold;
    background: #fff;
  }
}

.fight-cha {
  width: 90vw;
  height: 120vw;
  bottom: -30vw;

  &.opponent-side {
    right: -20vw;
  }

  &.user-side {
    left: -20vw;
    transform: rotateY(180deg);
  }
}

.scene-text {
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  transform: scale(0.6);
}
</style>
