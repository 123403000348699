<template>
  <div class="mini-map-modal" v-if="is_show">
    <button @click="closeModal" class="btn btn-sm btn-secondary circle font-weight-bolder" id="closebtn">
      X
    </button>
    <miniMap
      :map_config="map_config"
      :defaultUserCenter="defaultUserCenter"
      :flyTo="flyTo"
      :markers="markers"
    ></miniMap>
  </div>
</template>

<script>
import miniMap from "@/views/part/miniMap";

export default {
  name: "modal",
  computed: {
    content() {
      return this.modal?.content || "";
    }
  },
  components: {
    miniMap
  },
  methods: {
    closeModal() {
      this.$emit("toggleModal");
    }
  },
  props: {
    defaultUserCenter: {
      default: () => {
        return [25.0436, 121.5139];
      }
    },
    map_config: {
      required: true
    },
    flyTo: {
      required: false,
      default: false
    },
    markers: {
      default: () => []
    },
    is_show: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__var";
.mini-map-modal {
  transition: 0.5s;
  position: fixed;
  width: calc(100vw - 60px);
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  z-index: 99998;
  font-size: 20px;
  border-radius: 4px;
  padding: 10px;
  top: 80px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
}
#closebtn {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 99999;
  width: 30px;
  height: 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
}
</style>
