<template>
  <div :data-vimeo-url="vimeo_url" :data-vimeo-width="width" :data-vimeo-height="dataHeight" :id="element_id"></div>
</template>

<script>
import { mapGetters } from "vuex";
import player from "@vimeo/player";

export default {
  name: "vimeo",
  props: {
    vimeo_key: {
      required: true
    },
    autoplay: {
      default: false
    },
    dataWidth: {
      default: false
    },
    dataHeight: {
      default: false
    }
  },
  data() {
    return {
      width: 300,
      height: null,
      igVideo: null
    };
  },
  computed: {
    ...mapGetters("Video", ["getVideoPath", "getVimeoId"]),
    element_id() {
      return "vimeo-" + this.vimeo_key;
    },
    vimeo_url() {
      return this.getVimeoId(this.vimeo_key);
    }
  },
  mounted() {
    if (this.dataWidth) {
      this.width = this.dataWidth;
    } else {
      this.width = window.innerWidth;
    }

    if (this.dataHeight) {
      this.height = this.dataHeight;
    } else {
      this.height = window.innerHeight;
    }

    this.$nextTick(() => {
      this.loadVideo();
    });
  },
  methods: {
    loadVideo() {
      this.igVideo = new player(this.element_id, {
        autoplay: this.autoplay
      });
      if (this.$listeners.play) {
        this.igVideo.on("play", event => {
          this.$emit("play", event);
        });
      }
      if (this.$listeners.pause) {
        this.igVideo.on("pause", event => {
          this.$emit("pause", event);
        });
      }
      if (this.$listeners.timeupdate) {
        this.igVideo.on("timeupdate", event => {
          this.$emit("timeupdate", event);
        });
      }
      if (this.$listeners.ended) {
        this.igVideo.on("ended", event => {
          this.$emit("ended", event);
        });
      }
    }
  }
};
</script>

<style scoped></style>
