<template>
  <div class="full-page overflow-auto" ref="container">
    <FavorContainer
      v-if="flag('stage_3_phase5Clear') && flag('stage_3_step_5_favor') == false"
      @close="phase5bonusDone"
      :stage="stage"
      :step="3"
      :maximum_step="1"
      :stageFinished="true"
    ></FavorContainer>
    <stageHeader :character="stageCharacter" :stageLocation="stageLocation"></stageHeader>
    <moreContent @click="showProgressUpdate = false" v-if="showProgressUpdate"></moreContent>

    <div class="position-relative stage-content" ref="stage">
      <zhongshanHall v-if="!flag('stage_3_phase1Clear')"></zhongshanHall>
      <div v-else>
        <img src="img/stage3phase1-answer.png" alt="" />
      </div>
      <FavorContainer
        v-if="flag('stage_3_phase1Clear') && flag('stage_3_step_1_favor') == false"
        @close="stage3step1FavorOver"
        :stage="stage"
        :step="1"
      ></FavorContainer>
      <favorMomo v-if="findFavor" @next="closeFavor"></favorMomo>
      <stageMessage
        :user="user"
        :stageCharacter="siteCharacter.name"
        :data="phase1Messages"
        :stage="stage"
        :step="1"
        hint_key="stage_3_phase1_hint"
        v-on:stageClear="stage1Clear"
        v-on:hintAdd="parse1Hint"
      ></stageMessage>
      <checkPosition
        v-if="flag('stage_3_phase1Clear')"
        :location="check1stPosition"
        :local="[25.043052, 121.510361]"
      ></checkPosition>
      <transition name="fade">
        <igStory :video="phase1IgStory" v-if="flag('stage_3_check1stPlace')"></igStory>
      </transition>
      <stageMessageNoInput :data="phase1IgStoryMsg" v-show="flag('stage_3_check1stPlace')"></stageMessageNoInput>
      <template v-if="flag('stage_3_phase2_find1')">
        <zshFind1 @showfavor="showfavor(5)" @hintBtnClick="hintBtnClick" :step="5"></zshFind1>
        <stageMessageNoInput
          :data="phase2Find1.defaultMsg"
          :step="5"
          :hint_messages="getThisHint_message(5)"
        ></stageMessageNoInput>
        <!-- <stageMessageNoInput
          :data="phase2Find1.defaultMsg"
          :hint_messages="hint_message(1, 'phase2Find1')"
        ></stageMessageNoInput> -->
        <stageMessageNoInput v-if="flag('stage_3_phase2_find2')" :data="phase2Find1CleartMsg"></stageMessageNoInput>
      </template>
      <template v-if="flag('stage_3_phase2_find2')">
        <zshFind2 @showfavor="showfavor(6)" @hintBtnClick="hintBtnClick" :step="6"></zshFind2>
        <stageMessageNoInput
          :data="phase2Find2.defaultMsg"
          :step="6"
          :hint_messages="getThisHint_message(6)"
        ></stageMessageNoInput>
        <stageMessageNoInput v-if="flag('stage_3_phase2_find3')" :data="phase2Find2CleartMsg"></stageMessageNoInput>
      </template>
      <template v-if="flag('stage_3_phase2_find3')">
        <zshFind3 @showfavor="showfavor(7)" @hintBtnClick="hintBtnClick" :step="7"></zshFind3>
        <stageMessageNoInput
          v-if="flag('stage_3_phase2_find3')"
          :data="phase2Find3.defaultMsg"
          :step="7"
          :hint_messages="getThisHint_message(7)"
        ></stageMessageNoInput>
        <stageMessageNoInput v-if="flag('stage_3_phase2Clear')" :data="phase2Find3CleartMsg"></stageMessageNoInput>
      </template>
      <template v-if="flag('stage_3_phase2Clear')">
        <img class="img-fluid" src="/img/csh-phase3.jpg" alt="" />
        <FavorContainer
          v-if="flag('stage_3_phase3Clear') && flag('stage_3_step_2_favor') == false"
          @close="stage3step2FavorOver"
          :stage="stage"
          :step="2"
        ></FavorContainer>
        <stageMessage
          :user="user"
          :stageCharacter="siteCharacter.name"
          hint_key="stage_3_phase3_hint"
          :data="phase3Messages"
          :stage="stage"
          :step="3"
          v-on:stageClear="phase3Clear"
          v-on:hintAdd="parse3Hint"
        ></stageMessage>
      </template>
      <template v-if="flag('stage_3_phase3Clear')">
        <img
          @click="openBag"
          class="img-fluid"
          src="/img/csh-phase4a.jpg"
          alt=""
          v-if="!flag('stage_3_phase4OpenBag')"
        />
        <img
          @click="openSheet"
          class="img-fluid"
          src="/img/csh-phase4b.jpg"
          alt=""
          v-if="flag('stage_3_phase4OpenBag') && !flag('stage_3_phase4OpenSheet')"
        />
        <img
          class="img-fluid"
          src="/img/hug-sheet.png"
          alt=""
          v-if="flag('stage_3_phase4OpenSheet') && !flag('stage_3_phase4Clear')"
        />
        <div v-if="flag('stage_3_phase4Clear')">
          <img src="img/stage3phase4-anwser.png" alt="" />
        </div>
        <FavorContainer
          v-if="flag('stage_3_phase4Clear') && flag('stage_3_step_3_favor') == false"
          @close="stage3step3FavorOver"
          :stage="stage"
          :step="3"
        ></FavorContainer>
        <stageMessage
          :user="user"
          :stageCharacter="siteCharacter.name"
          hint_key="stage_3_phase4_hint"
          :data="phase4Messages"
          :stage="stage"
          :step="4"
          v-on:stageClear="phase4Clear"
          v-on:hintAdd="parse4Hint"
        ></stageMessage>
      </template>
      <template v-if="flag('stage_3_phase4Clear')">
        <photoMission
          :photo="photoMission"
          :stage="stage"
          @next="next"
          @final="final"
          @showStageFinishedFavor="showStageFinishedFavor"
        >
          <stageMessageNoInput :data="photoMissionMsg"></stageMessageNoInput>
        </photoMission>
      </template>
    </div>
    <mainMenu></mainMenu>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import mainMenu from "@/views/part/mainMenu";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import igStory from "@/views/part/igStory";
import stageHeader from "@/views/part/stageHeader";
import zhongshanHall from "@/views/part/zhongshanHall";
import zshFind1 from "@/views/part/zshFind1";
import zshFind2 from "@/views/part/zshFind2";
import zshFind3 from "@/views/part/zshFind3";
import moreContent from "@/views/part/moreContent";

import stageMessage from "@/views/part/stageMessage";
import stageMessageNoInput from "@/views/part/stageMessageNoInput";

import checkPosition from "@/views/part/checkPosition";
import photoMission from "@/views/part/photoMission";

import { mapGetters } from "vuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import FavorContainer from "./favor/FavorContainer";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import ShowUpdate from "@/Mixins/ShowUpdateMixin";
import favorMomo from "./part/favorMomo";
import { sendMessage } from "@/utils/WebSocket";
import FavorFromMixin from "@/Mixins/FavorFromMixin";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "csh",
  mixins: [
    Avatar,
    FavorFromMixin,
    ShowUpdate,
    CharacterFromVuex,
    UserFromVuex,
    StageFromMixin,
    TeamFromVuex,
    HowlAudio
  ],
  components: {
    mainMenu,
    stageHeader,
    stageMessage,
    stageMessageNoInput,
    zhongshanHall,
    zshFind1,
    zshFind2,
    zshFind3,
    checkPosition,
    photoMission,
    igStory,
    FavorContainer,
    favorMomo,
    moreContent
  },
  computed: {
    play_hug_music() {
      return this.flag("stage_3_step_5_hug_music") && !this.localFlag("hugPlayed");
    },
    stageCharacter() {
      return this.getRoleByName(this.siteCharacter.name);
    },
    hint_messages() {
      let result = {};
      let stage_id = this.stage?.stage_id.toString();
      if (this?.team_payload?.stageMessage?.[stage_id]) {
        // console.log("this?.team_payload?.stageMessage", this.team_payload.stageMessage);
        // let list = this.team_payload.stageMessage[stage_id];
        result = this.team_payload.stageMessage[stage_id];
        // Object.keys(list).forEach(step => {
        //   result[step] = list[step].filter(d => {
        //     if (d.is_hint && d.only) return d.only == this.user.id;
        //   });
        //   if (step == 1) {
        //     result[step] = {
        //       phase2Find1: result[step].slice(0, 3),
        //       phase2Find2: result[step].slice(3, 6),
        //       phase2Find3: result[step].slice(6, 9)
        //     };
        //   }
        // });
      }
      return result;
    }
  },
  watch: {
    play_hug_music: {
      immediate: true,
      handler: function(val) {
        if (val) {
          this.playHugMusic();
          this.setLocalFlag({
            key: "hugPlayed",
            status: true
          });
          console.log(this.localFlag("hugPlayed"));
        }
      }
    }
  },
  data() {
    return {
      showProgressUpdate: false,
      showProgressUpdateFlags: [
        "stage_3_phase1Clear",
        "stage_3_check1stPlace",
        "stage_3_phase2_find1",
        "stage_3_phase2_find2",
        "stage_3_phase2_find3",
        "stage_3_phase2Clear",
        "stage_3_phase3Clear",
        "stage_3_phase4Clear"
      ],
      stage: {
        stage_id: 3
      },
      siteCharacter: {
        name: "春哥"
      },
      stageLocation: {
        time: "高二下",
        location: "中山堂"
      },
      phase1Clear: false,
      phase1Messages: {
        unlockFlag: "stage_3_phase1Clear",
        msgLikes: "赤木和其他20個人都說讚",
        character: "春哥",
        defaultMsg: [
          {
            name: "春哥",
            content:
              "高二下學期的社團成果發表會，是我們高中生涯的最高潮，蟄伏兩年累積的能量，全在一個晚上爆發。在此之後，我們高二生就得<strong>從舞台離開</strong>，專心致志地準備指考。希望我們都別忘記刷吉他時手腕的<strong>上下擺動</strong>"
          },
          {
            name: "春哥",
            content: "<strong>不同的和弦組合成一首歌</strong>，妳可記得那首屬於我們的歌？"
          }
        ],
        answer: "擁抱",
        echoOfanswer: [
          "這首《擁抱》是我和小桃第一次學吉他練習的曲子，一切都從這裡開始",
          "脫下長日的假面，奔向夢幻的疆界……",
          "南瓜馬車之後，等著我們的是玻璃鞋，還是無盡黑夜"
        ],
        echoOfCharacter: ["春哥", "春哥", "春哥"],
        hintIndex: 0,
        hint: [
          "（1/4）試試拿著手機做彈吉他的動作，然後點擊地上的吉他<br>（使用提示會減少得到的好感值）",
          "（2/4）點擊吉他指板，每個和弦上都有一部分文字<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）和弦上的文字各別是：扌、亠、乡、隹、扌、包<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "擁抱"
        ]
      },
      check1stPlace: false,
      check1stPosition: {
        nextPhase: "stage_3_check1stPlace",
        position: [25.043052, 121.510361],
        distance: 50
      },
      phase1IgStory: {
        src: "S04",
        poster: "mc/9.jpg",
        autoplay: false,
        nextPhase: "stage_3_phase2_find1"
      },
      phase1IgStoryMsg: [
        {
          name: "小桃",
          content: "謝謝你們來參加我跟春哥的吉他社成果發表會！"
        },
        {
          name: "小希",
          content: "妳們很厲害欸，好好聽喔！"
        },
        {
          name: "花輪",
          content: "這束花獻給最耀眼的小桃"
        },
        {
          name: "春哥",
          content: "小桃，我幫你放書包"
        },
        {
          name: "胖子",
          content: "我有帶相機，我們來拍照！"
        },
        {
          name: "胖子",
          content: "來～再靠近一點喔！"
        },
        {
          name: "小桃",
          content: "耶！好開心！"
        },
        {
          name: "小桃",
          content: "哎……我的書包呢？我們剛剛不是放這裡的嗎？😳"
        },
        {
          name: "春哥",
          content: "怎麼會？對不起…是我沒顧好書包…"
        },
        {
          name: "小彥",
          content: "沒事沒事！應該是有人拿錯了，大家一起找，很快就找回來了！"
        }
      ],
      phase2Find1: {
        defaultMsg: [
          {
            name: "春哥",
            content:
              "書包怎麼突然不見了？怎麼會有人亂拿啊……先在中山堂門口這裡找找看好了，仔細看看<strong>有什麼不一樣的地方</strong>"
          }
        ],
        hintIndex: 0,
        hint: ["屋簷好像不太一樣", "門柱好像不太一樣", "階梯好像不太一樣"]
      },
      phase2Find1CleartMsg: [
        {
          name: "路人",
          content: "你們在找東西嗎？"
        },
        {
          name: "春哥",
          content: "我們的書包不見了，請問你有看到有人拿著書包離開嗎？"
        },
        {
          name: "路人",
          content: "我在這裡等人的時候，看到拿著書包離開的有：<strong>三個高中女生、一個高中男生跟一個成年男子</strong>"
        }
      ],
      phase2Find2: {
        defaultMsg: [
          {
            name: "春哥",
            content: "到中山堂門口的對面那邊找找看好了，說不定在那裡，仔細看看有什麼不一樣的地方吧！"
          }
        ],
        hintIndex: 0,
        hint: ["書卷好像不太一樣", "畫面中間地板怪怪的", "畫面左邊石頭怪怪的"]
      },
      phase2Find2CleartMsg: [
        {
          name: "春哥",
          content: "還是沒看到書包，問問看路人好了"
        },
        {
          name: "春哥",
          content: "我們的書包不見了，請問你有看到有人拿著書包離開嗎？"
        },
        {
          name: "路人",
          content:
            "我有看到一位成年男子往<strong>國父銅像</strong>方向走去，另外一位高中男生往<strong>雙層花圃</strong>的方向走去"
        }
      ],
      phase2Find3: {
        defaultMsg: [
          {
            name: "春哥",
            content: "到廣場找找看好了，那裡還沒找過，仔細看看有什麼不一樣的地方"
          }
        ],
        hintIndex: 0,
        hint: ["畫面中間地板好像不太一樣", "花台好像不太一樣", "兩棵樹中間好像不太一樣"]
      },
      phase2Find3CleartMsg: [
        {
          name: "春哥",
          content: "怎麼還是沒看到書包…問問看路人好了"
        },
        {
          name: "春哥",
          content: "我們的書包不見了，請問你有看到有人拿著書包離開嗎？"
        },
        {
          name: "路人",
          content: "我有看到一位<strong>成年男子在翻書包裡的東西</strong>"
        }
      ],
      phase3Messages: {
        msgLikes: "赤木和其他20個人都說讚",
        character: "春哥",
        defaultMsg: [
          {
            name: "春哥",
            content:
              "等一等！如果把三個路人的說詞全部結合起來，我好像知道發生什麼事了！書包一定在<strong>那</strong>附近！"
          }
        ],
        answer: ["國父雕像", "雕像", "國父銅像", "銅像", "孫中山先生銅像"],
        echoOfanswer: [
          "根據我的推理，在拿著書包離開的人裡面只有成年男子是最不可能擁有書包的，而且有人目擊到他在翻找書包，應該是在翻找值錢的物品。為了不引起懷疑一定會把書包隨手丟在隱密處！快到國父銅像那裡找找！",
          "找到了！書包果然在這裡！"
        ],
        echoOfCharacter: ["春哥", "春哥"],
        hintIndex: 0,
        hint: [
          "（1/4）推測是誰拿走了書包，輸入書包的所在位置<br>（使用提示會減少得到的好感值）",
          "（2/4）成年男子在廣場上翻找書包有點可疑<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）看起來是成年男子偷了書包，他往哪裡去了呢？書包對他沒什麼價值，應該會丟在那個地方附近<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "國父銅像"
        ]
      },
      phase4Messages: {
        msgLikes: "赤木和其他20個人都說讚",
        character: "春哥",
        defaultMsg: [
          {
            name: "春哥",
            content: "快看看<strong>書包裡面</strong>小桃的東西還在不在！"
          }
        ],
        answer: [
          "秘多啦密淚手淚",
          "MiDoLaMiReSolRe",
          "Mi Do La Mi Re Sol Re",
          "ecaedgd",
          "ECAEDGD",
          "3163252",
          "midolamiresolre",
          "mi do la mi re sol re"
        ],
        hintIndex: 0,
        hint: [
          "（1/4）點擊書包會出現琴譜，點擊琴譜會出現整張琴譜<br>（使用提示會減少得到的好感值）",
          "（2/4）某些文字的附近有音符，根據等式都換算成同一種音符看看<br>（使用提示會減少得到的好感值）",
          { content: "下個提示很接近正確答案囉！再次點擊提示按鈕查看" },
          "（3/4）將所有音符都根據等式換成最右邊的八分音符，八分音符的數量就是文字出現的順序，按照順序輸入音符旁邊的國字<br>（使用提示會減少得到的好感值）",
          { content: "放棄了嗎？再次點擊提示按鈕查看答案" },
          "秘多啦密淚手淚"
        ]
      },
      photoMission: {
        img: "photo-csh.jpg",
        flag: "stage_3_clear",
        finish: "stage_3_photo_clear",
        local: [25.04292, 121.509842],
        flyTo: [25.043364, 121.509901]
      },
      photoMissionMsg: [
        {
          name: "春哥",
          content: "在妳身邊的每一天，就是最好的一天：)"
        },
        {
          name: "提示",
          content:
            "你們可以參考範例照片的地點和姿勢、重現當年的回憶；或是自行選擇你們喜愛的地點、姿勢或方式，拍照並上傳照片，<strong>照片請用正方形格式拍攝，超出將會被剪裁。</strong>"
        }
      ],
      scrollBtnsShow: true,
      findFavor: false
    };
  },
  mounted() {
    this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    // if(this.$refs.container.scrollTop>400){
    //   this.$refs.container.scrollTop = this.$refs.stage.scrollHeight;
    // }
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getThisHint_message(step) {
      return this.hint_messages?.[step];
    },
    showfavor(step) {
      this.findFavor = true;
      this.setWinner({
        winner: this.character,
        user: this.user,
        stage: { stage_id: 3 },
        step: step
      });
      this.addScoreByRate({
        score: 2,
        characterName: this.character?.name,
        isMoveStage: false,
        no_hint: true
      });
    },
    closeFavor() {
      this.findFavor = false;
    },
    handleScroll() {
      if (this.showProgressUpdate) {
        this.showProgressUpdate = false;
      }
    },
    final() {
      this.unlockStage(3);
      // this.setFlag({ key: "stage_3_location", status: [25.045175, 121.510708] });
    },
    next() {
      this.setFlag({ key: "stage_3_photo_clear", status: true });
    },
    parse1Hint() {
      if (this.flag("stage_3_phase1Clear")) return;
      this.phase1Messages.hintIndex = this.phase1Messages.hintIndex + 1;
    },
    stage3step1FavorOver() {
      this.setFlag({ key: "stage_3_step_1_favor", status: true });
    },
    stage3step2FavorOver() {
      this.setFlag({ key: "stage_3_step_2_favor", status: true });
    },
    stage3step3FavorOver() {
      this.setFlag({ key: "stage_3_step_3_favor", status: true });
    },
    stage1Clear: function() {
      if (this.flag("stage_3_phase1Clear")) return;
      //   this.setFlag({ key: "stage_3_phase1Clear", status: true });
      //   return;
      // }
      this.setFlag({ key: "stage_3_phase1Clear", status: true });
      this.addScoreByRate({
        score: 10,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_3_phase1_hint"
      });
    },
    parse3Hint() {
      if (this.flag("stage_3_phase3Clear")) return;
      this.phase3Messages.hintIndex++;
    },
    phase3Clear: function() {
      if (this.flag("stage_3_phase3Clear")) return;
      this.setFlag({ key: "stage_3_phase3Clear", status: true });
      this.addScoreByRate({
        score: 10,
        characterName: this.character?.name,
        isMoveStage: false,
        hint_key: "stage_3_phase3_hint"
      });
    },
    openBag: function() {
      if (this.flag("stage_3_phase4OpenBag")) return;
      this.setFlag({ key: "stage_3_phase4OpenBag", status: true });
      this.sendStageMessage({
        step: 4,
        stage: this.stage,
        newMsg: {
          character: "春哥",
          content:
            "咦！這不是我以前給她的吉他譜嗎？她竟然還留著！上面的<strong>音符由短到長排列</strong>後，竟然變成了一段<strong>旋律</strong>，這個熟悉的<strong>旋律</strong>不就是…？",
          stage: this.stage
        }
      });
    },
    openSheet: function() {
      if (this.flag("stage_3_phase4OpenSheet")) return;
      this.setFlag({ key: "stage_3_phase4OpenSheet", status: true });
    },
    parse4Hint() {
      if (this.flag("stage_3_phase4Clear")) return;
      this.phase4Messages.hintIndex++;
    },
    phase4Clear: function() {
      if (this.flag("stage_3_phase4Clear")) return;
      this.sendStageMessage({
        step: 4,
        stage: this.stage,
        newMsg: {
          character: "春哥",
          content: "我們之間⋯⋯和擁抱的旋律，都是我永遠忘不了的",
          stage: this.stage
        }
      });
      this.setFlag({ key: "stage_3_step_5_hug_music", status: true });
    },
    playHugMusic() {
      let audio = this.makeAudio("/audio/hug.mp3");
      audio.play();
      setTimeout(() => {
        this.setFlag({ key: "stage_3_phase4Clear", status: true });
        this.addScoreByRate({
          score: 10,
          characterName: this.character?.name,
          isMoveStage: false,
          hint_key: "stage_3_phase4_hint"
        });
      }, 8000);
    },
    phase5bonusDone: function() {
      this.setFlag({ key: "stage_3_step_5_favor", status: true });
      this.$router.replace("homeMap");
    },
    showStageFinishedFavor() {
      this.setFlag({ key: "stage_3_phase5Clear", status: true });
    },
    hintBtnClick(hint_key, step) {
      console.log("hint_key", hint_key, step);
      let hintIndex;
      let hint;
      switch (hint_key) {
        case "phase2Find1":
          hintIndex = this.hintCount(hint_key) || this.phase2Find1.hintIndex;
          hint = this.phase2Find1.hint;
          break;
        case "phase2Find2":
          hintIndex = this.hintCount(hint_key) || this.phase2Find2.hintIndex;
          hint = this.phase2Find2.hint;
          break;
        case "phase2Find3":
          hintIndex = this.hintCount(hint_key) || this.phase2Find3.hintIndex;
          hint = this.phase2Find3.hint;
          break;
      }
      if (hintIndex < hint.length) {
        let newMsg = {
          character: null,
          content: hint[hintIndex],
          result: "",
          is_hint: true
        };

        // console.log(this.data.hint[hintIndex])
        let messageData = {
          method: "StageMessage",
          token: this.token,
          stage: this.stage,
          step: step,
          only: this.user.id,
          hintIndex: hintIndex,
          newMsg
        };
        sendMessage(messageData);

        let score = 0;
        messageData = {
          method: "GetHint",
          token: this.token,
          stage: this.stage,
          step: this.step,
          only: this.user.id,
          score,
          hintIndex: hintIndex
        };
        sendMessage(messageData);
        this[hint_key].hintIndex++;
      }
    }
  }
};
</script>

<style scoped></style>
