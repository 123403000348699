<template>
  <div class="full-page position-relative d-flex flex-column overflow-hidden fighting-bg">
    <!-- 鄉民加油手機轉橫向提示畫面 -->
    <!-- <div class="fighting-mobile mobile-bg-black position-fixed">
      <img src="/img/mobile-rotate.svg" alt="" />
    </div> -->

    <FavorContainer
      v-if="flag('fighting_over') && fighting_bonus == false"
      @close="fightBonusOver"
      :stage="stage"
      :step="game_times"
    ></FavorContainer>
    <div class="opponent-block">
      <div class="position-relative">
        <div class="fighting-info-bar d-flex">
          <div class="w-100">
            <div class="progress-left">
              <div class="d-flex">
                <div class="gamer-bar">1P {{ player_1.name }}</div>
              </div>
              <div class="fighting-bar-wrap position-relative">
                <div class="fight-corner"></div>
                <progressBar :percent="player1_life"></progressBar>
                <powerBar :percent="player1_power"></powerBar>
              </div>
            </div>
          </div>
        </div>

        <div class="fighting-cha-circle circle position-absolute">
          <img class="cover-fit" v-bind:src="showAvatar(player_1.picture)" alt="" />
        </div>
      </div>
    </div>

    <div class="fighting-scene position-relative overflow-hidden">
      <div class="position-absolute fight-cha user-side">
        <img class="contain-fit" :src="'/img/' + player1_animate" alt="" />
      </div>
      <div class="position-absolute fight-cha opponent-side">
        <img class="contain-fit" :src="'/img/' + player2_animate" alt="" />
      </div>
    </div>
    <div class="position-absolute fight-countdown text-center">
      <div class="time-title">TIME</div>
      <div class="time-panel">
        {{ time }}
      </div>
    </div>
    <div class="control-block">
      <div class="position-relative h-100">
        <div class="fighting-info-bar d-flex">
          <div class="fighting-cha-circle circle position-absolute">
            <img class="cover-fit" v-bind:src="showAvatar(player_2.picture)" alt="" />
          </div>
          <div class="w-100">
            <div class="progress-right">
              <div class="d-flex justify-content-end">
                <div class="gamer-bar gamer-bar-right">2P {{ player_2.name }}</div>
              </div>
              <div class="fighting-bar-wrap position-relative">
                <div class="fight-corner"></div>
                <progressBar :percent="player2_life" :direction="'justify-content-end'"></progressBar>
                <powerBar :percent="player2_power" :direction="'justify-content-end'"></powerBar>
              </div>
            </div>
          </div>
        </div>

        <div class="fight-act-block">
          <div class="charge-btn user-side " @click="charge1P">
            <div>
              <div>幫 1P</div>
              <div class="charge-word">集氣</div>
            </div>
          </div>
          <div class="charge-btn opponent-side " @click="charge2P">
            <div>
              <div>幫 2P</div>
              <div class="charge-word">集氣</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      <div class="fighting-cha-wrap position-absolute fighting-opponent-avatar">-->
    <!--        -->
    <!--        -->
    <!--      </div>-->

    <div class="position-fixed scene-text">
      <img v-show="textReady" src="img/fight-text-ready.png" alt="" />
      <img v-show="textFight" src="img/fight-text-fight.png" alt="" />
      <img v-show="textFinal" src="img/fight-text-win.png" alt="" />
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import progressBar from "@/views/part/progressBar";
import powerBar from "@/views/part/powerBar";
import { mapActions, mapGetters } from "vuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import throttle from "lodash.throttle";
import HowlAudio from "@/Mixins/HowlAudio";

import FavorContainer from "./favor/FavorContainer";
import FightMixin from "@/Mixins/FightMixin";

export default {
  name: "fightingBystand",
  mixins: [
    Avatar,
    CharacterFromVuex,
    WebSocketMixin,
    TeamFromVuex,
    UserFromVuex,
    StageFromMixin,
    FightMixin,
    HowlAudio
  ],
  components: {
    progressBar,
    powerBar,
    FavorContainer
  },
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Fight", [
      "player1_animate",
      "player2_animate",
      "player1_power",
      "player2_power",
      "player1_life",
      "player2_life",
      "game_times"
    ]),
    game_over() {
      return this.player1_life <= 0 || this.player2_life <= 0 || this.time == 0;
    }
  },
  watch: {
    stage_4_fightingFinish(val){
      if(val){
        this.$router.replace("park228");
      }
    },
    team_payload: {
      deep: true,
      handler() {
        this.reset();
      }
    },
    game_over(val, oldVal) {
      if (val == oldVal) return;
      if (val == true) {
        this.textFinal = true;
        this.audio_winner.play();
        this.supplyGoal();
        clearInterval(this.timer);
        this.fightOverTimeout = setTimeout(this.fightOver, 3000);
      }
    }
  },
  data() {
    return {
      stage: { stage_id: 7 },
      fighting_bonus: false,
      ready: false,
      time: 60,
      timer: null,
      textReady: true,
      textFight: false,
      textFinal: false,
      audio_bgm: this.makeAudio("/audio/fighting-bgm.mp3"),
      audio_ready: this.makeAudio("/audio/fighting-ready.mp3"),
      audio_winner: this.makeAudio("/audio/fighting-game-set.mp3"),
      supply_times: {
        player1: 0,
        player2: 0
      },
      player_1: {
        name: "赤木",
        picture: "avatar5@2x.jpg",
        life: 100,
        charge: 0
      },
      player_2: {
        name: "花輪",
        picture: "avatar3@2x.jpg",
        life: 100,
        charge: 0
      }
    };
  },
  mounted: function() {
    this.reset();

    this.audio_ready.play();
    setTimeout(this.showFight, 1200);
  },
  methods: {
    ...mapActions("Fight", ["charge", "addSupplyTimes", "supplyGoal"]),
    reset() {
      let player1 = this?.team_payload?.fighting?.role.player1;
      let player2 = this?.team_payload?.fighting?.role.player2;
      this.player_1.name = player1.character.name;
      this.player_1.picture = player1.character.picture;
      this.player_2.name = player2.character.name;
      this.player_2.picture = player2.character.picture;
      this.audio_bgm.play();
    },
    fightBonusOver() {
      this.fighting_bonus = true;
      this.$router.replace("park228");
    },
    showFight() {
      this.textFight = true;
      this.textReady = false;
      setTimeout(this.beginCountDown, 1200);

      // this.timer()
    },
    beginCountDown() {
      this.textFight = false;
      this.fightTimer();
    },
    fightTimer() {
      // let my = this
      this.timer = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          clearInterval(this.timer);
          this.textFinal = true;
        }
      }, 1000);
    },
    chargeCount: key => this.addSupplyTimes(key),
    charge1P: throttle(function() {
      if (this.game_over) return;
      this.addSupplyTimes("player1");
      this.charge({ player: "player1" });
    }, 1000),
    charge2P: throttle(function() {
      if (this.game_over) return;
      this.addSupplyTimes("player2");
      this.charge({ player: "player2" });
    }, 1000)
  },
  beforeDestroy() {
    this.audio_bgm.pause();
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__var";

.fighting-bg {
  background: url("/img/fightSecne-Bg.jpg") center center;
  background-size: cover;
  color: #1c334f;
}

.gamer-bar {
  margin-top: 2px;
  background: $fighting-dark;
  color: #fff;
  font-size: 12px;
  border-radius: 0 20px 20px 0;
  padding: 0px 30px;
  margin-left: -20px;
  border: 2px solid #fff;

  &.gamer-bar-right {
    margin-left: 0px;
    margin-right: -20px;
    border-radius: 20px 0 0 20px;
  }
}

.fighting-cha-circle {
  height: 75px;
  width: 75px;
  border: 5px solid $fighting-dark;
  z-index: 10;
}

.fighting-bar-wrap {
  border: 5px solid $fighting-dark;

  .fight-corner {
    position: absolute;
    background: $fighting-dark;
    top: 0;
    bottom: 0;
    width: 10px;
  }
}

.progress-left {
  margin-left: 60px;

  .fighting-bar-wrap {
    clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0 100%);

    .fight-corner {
      right: 0;
      clip-path: polygon(6px 0, 100% 0%, 100% 100%, 0 100%);
    }
  }
}

.progress-right {
  margin-right: 60px;

  .power-bar-bg {
    justify-content: flex-end;
  }

  .fighting-bar-wrap {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10px 100%);

    .fight-corner {
      left: 0;
      clip-path: polygon(0 0, 4px 0, 100% 100%, 0% 100%);
    }
  }
}

.fighting-info-bar {
  /*height: 65px;*/
  width: 100%;
}

.fighting-status {
  //top: 20px;
  //width: calc(100% - 30px);
  //left: 50%;
  //transform: translateX(-50%);
}

.opponent-block {
  padding: 50px 30px 30px;

  .fighting-cha-circle {
    left: 0;
    top: 0;
  }
}

.fight-countdown {
  right: 30px;
  bottom: 0px;
  width: 91px;
  background: #fff;
  padding: 22px 0 30px;
  border-radius: 50px 50px 0 0;

  .time-title {
    font-weight: bold;
    bargin-bottom: 10px;
  }

  .time-panel {
    padding: 10px;
    font-size: 32px;
    color: #1c334f;
    font-weight: bold;
    background: #fff;
  }
}

.fight-cha {
  width: 90vw;
  height: 120vw;
  bottom: -30vw;

  &.opponent-side {
    right: -20vw;
  }

  &.user-side {
    left: -20vw;
    transform: rotateY(180deg);
  }
}

//.fighting-bar-wrap {
//  top: 10px;
//  border: 5px solid $fighting-dark;
//  width: calc(50vw - 55px);
//
//  &.fighting-bar-user {
//    .fighting-bar {
//      justify-content: flex-end;
//    }
//
//    .power-bar-bg {
//      justify-content: flex-end;
//    }
//  }
//
//  &.fighting-bar-opponent {
//    right: 0;
//  }
//}

//.fighting-cha-wrap {
//  z-index: 10;
//  bottom: 20px;
//
//  &.fighting-user-avatar {
//    left: 15px;
//  }
//
//  &.fighting-opponent-avatar {
//    right: 15px;
//
//    .fighting-cha-circle {
//      right: 0;
//    }
//  }
//}

.fighting-scene {
  height: 56.22vw;
  background: url("/img/fighting_bg.jpg") center center;
  background-size: cover;
}

.control-block {
  padding: 30px;
  flex-grow: 1;

  .fighting-cha-circle {
    right: 0;
    top: 0;
  }
}

.fight-act-block {
  margin-top: 20px;

  & > div {
    //margin-bottom: 20px;
  }

  //.action-title {
  //  font-size: 18px;
  //  background: #fff;
  //  margin-left: -30px;
  //  margin-right: 16px;
  //  border-radius: 0 30px 30px 0;
  //  padding: 7px 14px 7px 40px;
  //  @include default-shadow;
  //}
}

.scene-text {
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.charge-btn {
  position: absolute;
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  color: #1c334f;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 20;
  font-size: 12px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;

  &.user-side {
    //left: 30px;
    //margin-left: -30px;
    //margin-bottom: 20px;
  }

  &.opponent-side {
    //margin-left: -30px;
    //margin-left: 90px;
    //margin-right: 16px;
    //border-radius: 0 30px 30px 0;
    //padding: 7px 14px 7px 40px;
    //margin-right: 16px;
    right: 20px;
    //left: 60px;
  }

  .charge-word {
    font-size: 32px;
    font-weight: bold;
  }

  &:active {
    transform: scale(0.9);
    background: rgba(255, 255, 255, 0.8);
  }
}
</style>
