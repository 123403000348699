<template>
  <div>
    <h3 class="text-white"></h3>
    <p class="">
      沒問題了？那就趕快出發，一起約會吧！
    </p>
    <div class="position-relative">
      <div class="select-user cha-circle cha-circle-s circle" v-for="row in ready" :key="row.id">
        <img v-bind:src="getAvatar(row)" v-bind:alt="row.nickname" />
      </div>
    </div>
    <button class="btn btn-block btn-primary btn-sm" @click="doSetReady">開始</button>

    <div class="text-white position-absolute hint-text">等待其他隊員</div>
  </div>
</template>

<script>
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import StageFromMixin from "../../Mixins/StageFromMixin";
import UserFromVuex from "../../Mixins/UserFromVuex";
import Avatar from "../../Mixins/Avatar";
import { mapActions, mapGetters } from "vuex";
import { getDeviceMotionPermission } from "@/utils/Motion";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import ModalMixin from "@/Mixins/ModalMixin";

export default {
  name: "datingRuleEnd",
  mixins: [WebSocketMixin, StageFromMixin, UserFromVuex, TeamFromVuex, ModalMixin, Avatar],
  computed: {
    ...mapGetters("Dating", ["ready"]),
    avatar() {
      if (this.user.avatar.includes("default_avatar.jpg")) return this.character.picture;
      return this.user.avatar;
    }
  },
  methods: {
    ...mapActions("Dating", ["sendReady"]),
    doSetReady() {
      getDeviceMotionPermission();
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人都在線上才能開始遊戲");
        return;
      }
      let user = { avatar: this.avatar };
      this.character.user = user;
      this.sendReady({
        character: this.character
      });
    }
  }
};
</script>

<style scoped lang="scss">
.select-user {
  position: absolute;
  top: -20px;
  /*right:0;*/
  @for $i from 1 through 8 {
    &:nth-child(#{$i}) {
      right: $i * 20-20px;
    }
  }
}
.hint-text {
  background: #3c3c3c;
  color: #fff;
  padding: 8px 0;
  text-align: center;
  bottom: -60px;
  right: -30px;
  left: -30px;
}
</style>
