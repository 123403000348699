<template>
  <div>
    <h3 class=""></h3>
    <p class="text-white">
      規則沒問題了，那就開始囉！<br />需進行<strong>至少兩回合</strong>才可進行結算<br />
      Let's dance battle！
    </p>
    <a class="btn btn-block btn-primary btn-sm text-white" @click="start">開始</a>
    <div class="text-white position-absolute hint-text">等待其他隊員</div>
  </div>
</template>

<script>
import { getDeviceMotionPermission } from "@/utils/Motion";

export default {
  name: "danceRuleEnd",
  methods: {
    start() {
      getDeviceMotionPermission();
      this.$router.replace("/danceStandBy");
    }
  }
};
</script>

<style scoped>
.hint-text {
  background: #3c3c3c;
  color: #fff;
  padding: 8px 0;
  text-align: center;
  bottom: -60px;
  right: -30px;
  left: -30px;
}
</style>
